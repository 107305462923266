import { TiWeatherStormy } from "react-icons/ti";
import { VscBook, VscFeedback } from "react-icons/vsc";
import { FaFish, FaWater, FaQuestion } from "react-icons/fa";
import { LuTable } from "react-icons/lu";
import { TiWeatherWindyCloudy } from "react-icons/ti";
import { TbZoomInArea } from "react-icons/tb";
import { FaGlobeAmericas } from 'react-icons/fa';
import logoImage from '../../images/logos/transparentLogoWhite.png';
import React, { useState, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { BiDonateHeart } from "react-icons/bi";
import { FaArrowUpShortWide } from "react-icons/fa6";
import { FaArrowDownShortWide } from "react-icons/fa6";
import { FaFileSignature } from "react-icons/fa";
import { FaMapMarkedAlt } from "react-icons/fa";

const BottomNav = () => {
    
    const location = useLocation();
    const largeIconSize = 25;

    const activeColor = 'orange';
    const defaultColor = 'black';

    const [isExpanded, setIsExpanded] = useState(false);

    const menuItems = [
        {
            name: 'Weather Map',
            url: '/',
            position: 'bottom',
            icon: <TiWeatherStormy size={largeIconSize} />
        },
        {
            name: 'Fishing Map',
            url: '/fishingMap',
            position: 'bottom',
            icon: <FaFish size={largeIconSize} />
        },
        {
            name: 'Destinations Map',
            url: '/destinationsMap',
            position: 'bottom',
            icon: <FaMapMarkedAlt size={largeIconSize} />
        },
        {
            name: 'Weather Table',
            url: '/weatherTable',
            position: 'bottom',
            icon: <LuTable size={largeIconSize} />
        },
        {
            name: 'Weather Forecast',
            url: '/weatherForecast',
            position: 'bottom',
            icon: <TiWeatherWindyCloudy size={largeIconSize} />
        },
        {
            name: 'Expand',
            // url: '/expand',
            position: 'bottom',
            icon: isExpanded ? <FaArrowDownShortWide size={largeIconSize} /> : <FaArrowUpShortWide size={largeIconSize} />
        },
        {
            name: 'Terms & Conditions',
            url: '/termsAndConditions',
            position: 'side',
            icon: <FaFileSignature size={largeIconSize} />
        },
        {
            name: 'Support',
            url: 'https://buymeacoffee.com/sportyapp',
            position: 'side',
            icon: <BiDonateHeart size={largeIconSize} />
        },
        {
            name: 'Help',
            url: '/helpPage',
            position: 'side',
            icon: <FaQuestion size={largeIconSize} />
        },
        {
            name: 'Feedback',
            url: '/feedback',
            position: 'side',
            icon: <VscFeedback size={largeIconSize} />
        },
        {
            name: 'Area Map',
            url: '/fishingAreaMap',
            position: 'side',
            icon: <FaGlobeAmericas size={largeIconSize} />
        },
        {
            name: 'Area Status',
            url: '/areaStatus',
            position: 'side',
            icon: <TbZoomInArea size={largeIconSize} />
        },
        {
            name: 'Tides',
            url: '/tides',
            position: 'side',
            icon: <FaWater size={largeIconSize} />
        },
    ];

    return (
        <div className="bottom-nav-container">
            {menuItems.map((item, index) => {
                return (item.position === 'bottom') &&
                <NavLink
                    key={index}
                    to={item.url}
                    onClick={(item.name === 'Expand') ? () => setIsExpanded(!isExpanded) : null}
                    className="bottom-nav-item"
                    style={{ color: location.pathname === item.url ? activeColor : defaultColor }}
                >
                    <div className="bottom-nav-item-icon">{item.icon}</div>
                </NavLink>
            })}
            {isExpanded && 
            <div className="side-menu">
                {menuItems.map((item, index) => {
                    return (item.position === 'side') &&
                    <NavLink
                        key={index}
                        to={item.url}
                        className="side-nav-item"
                        style={{ color: location.pathname === item.url ? activeColor : defaultColor }}
                    >
                        <div className="side-nav-item-icon">{item.icon}</div>
                    </NavLink>
                })}
            </div>
            }
        </div>
    );
};

export default BottomNav;
