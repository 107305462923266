import { useState, useContext, useEffect, useRef, useCallback, use } from 'react';
import ReactMapGl, { Marker, Popup, Source, Layer } from 'react-map-gl';
import ForecastSlider from '../weatherMap/forecastSlider.jsx';
import WindSpeedChart from '../weatherMap/windSpeedChart.jsx';
import { newWebCamArray } from '../../weatherData/newWebcams.jsx';
import { Context } from '../context/context.jsx';
import SlidingPane from "react-sliding-pane";
import { convertUnitsUserSettings } from '../../functions/convertUnits.js';
import WeatherLayerCheckBox from '../weatherMap/weatherLayerCheckbox.jsx';
import { ref, uploadBytes, getDownloadURL, updateMetadata, listAll, getMetadata } from 'firebase/storage';
import { storage } from '../global/firebaseConfig.jsx';
import SidebarPro from '../global/sidebar.jsx';
import BottomNav from '../global/bottomNav.jsx';
import { transformTime } from '../../functions/transformTime.js';
import ModalCustom from '../global/modalCustom.jsx';
import WindSpeedWindow from '../weatherMap/windSpeedWindow.jsx';
import SimpleCloseBtn from '../global/simpleCloseBtn.jsx';
import { useLocation } from 'react-router-dom';
import { NavLink, useNavigate } from 'react-router-dom';
import { FiWind } from "react-icons/fi";
import { GiBigWave } from "react-icons/gi";
import { Chart as ChartJS, layouts, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import PopupSelector from '../global/popupSelector.jsx';
import WeatherSettingsBtn from '../weatherMap/weatherSettingsBtn';
import WeatherMapUnits from '../weatherMap/weatherMapUnits';
import geojsonLandStencil from '../../files/ne_10m_coastline.json';
import geojsonLandFill from '../../files/ne_10m_land.json';

const WeatherMapBoxTileSet = () => {

  const navigate = useNavigate();
  const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY;
  const weatherStation = useContext(Context).weatherStation
  const userLocation = useContext(Context).userLocation
  const waveBuoy = useContext(Context).waveBuoy
  const s3Files = useContext(Context).s3Files
  const [fileName, setFileName] = useState(null);
  const [forecastType, setForecastType] = useState('wind');
  const [forecastTimeArray, setForecastTimeArray] = useState([]);
  const [currentTime, setCurrentTime] = useState('');
  const [playForecast, setPlayForecast] = useState(false);
  const [weatherHoverValue, setWeatherHoverValue] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [render, setRender] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [selectedWebcam, setSelectedWebcam] = useState(null);
  const [showFullSizePhoto, setShowFullSizePhoto] = useState(false);
  const [mousePosition, setMousePosition] = useState({ lng: -123.8, lat: 49.34 });
  const [clickedForecastArea, setClickedForecastArea] = useState(null);
  const [photoArray, setPhotoArray] = useState([]);
  const [forecastHour, setForecastHour] = useState(1);
  const [sidePanel, setSidePanel] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });
  const [mobile, setMobile] = useState(window.innerWidth < 600);
  const [hour, setHour] = useState(0);
  const [showSettingsDropdown, setShowSettingsDropdown] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(0);
  const [s3FolderName, setS3FolderName] = useState('windFiles');
  const [weatherCheckboxStatus, setWeatherCheckboxStatus] = useState({
    waveHeight: 'on',
    windSpeed: 'on',
    webCams: 'on',
    photos: 'on',
    windAnimation: 'on',
  });
  const [weatherUnits, setWeatherUnits] = useState({
    waveHeight: 'feet',
    windSpeed: 'miles',
  });
  const mapRef = useRef();
  const weatherDataRef = useRef([]);
  const intervalRef = useRef(null);
  const forecastTimes = useRef([]); // Ref to store forecast times
  let animationFrameId = null;
  const mediumRange = 0.3;
  const location = useLocation();

  const [fileIndex, setFileIndex] = useState(0);
  const [file, setFile] = useState(s3Files[fileIndex]);

  useEffect(() => {
    setTimeout(() => {

      setRender(true);
      
    }, 500);

  }, []);

  const convertGeometryCollection = (geojson) => {
    return {
      type: "FeatureCollection",
      features: geojson.geometries.map((geometry) => ({
        type: "Feature",
        geometry,
        properties: {}, // Add any properties you want here
      })),
    };
  };
  
  const loadAndConvertData = async () => {
    try {
        const featureCollection = convertGeometryCollection(geojsonLandFill);
        const featureCollection2 = convertGeometryCollection(geojsonLandStencil);
    
        // Get the map instance
        const map = mapRef.current?.getMap();
        if (!map) return;

        // 🌟 Ensure correct removal of ocean/land layers & sources
        if (forecastType === 'wind') {
            try {
                const layers = ['ocean-source', 'land-source']; // ✅ Match added IDs

                layers.forEach((layerId) => {
                    if (map.getLayer(layerId)) {
                        console.log(`Removing layer: ${layerId}`);
                        map.removeLayer(layerId);
                    }
                    if (map.getSource(layerId)) {
                        console.log(`Removing source: ${layerId}`);
                        map.removeSource(layerId);
                    }
                });

            } catch (error) {
                console.error('Error while removing layers or sources:', error);
            }
        }

        // 🌊 Add ocean and land layers only when switching to 'wave'
        if (forecastType === 'wave') {
            if (!map.getSource('ocean-source')) {
                map.addSource('ocean-source', {
                    type: 'geojson',
                    data: featureCollection,
                });

                map.addLayer({
                    id: 'ocean-source',
                    type: 'fill',
                    source: 'ocean-source',
                    paint: { 'fill-color': 'rgb(36, 73, 139)' },
                },);
            }

            if (!map.getSource('land-source')) {
                map.addSource('land-source', {
                    type: 'geojson',
                    data: featureCollection2,
                });

                map.addLayer({
                    id: 'land-source',
                    type: 'line',
                    source: 'land-source',
                    paint: {
                        'line-color': 'rgba(255,255,255,0.5)',
                        'line-width': .5,
                    },
                  });
                // }, map.getStyle().layers[map.getStyle().layers.length - 1].id);
            }
        }
    } catch (error) {
        console.error('Error loading or processing data:', error);
    }
};

// 📌 Ensure the effect triggers when forecastType changes
useEffect(() => {
    loadAndConvertData();
}, [forecastType]); 

  
  const windDirectionToAngle = (windDirection) => {
    const directions = {
      N: 270,
      NNE: 248,
      NE: 225,
      ENE: 208,
      E: 180,
      ESE: 158,
      SE: 135,
      SSE: 112,
      S: 90,
      SSW: 23,
      SW: 45,
      NW: 315,
      NNW: 293,
      WSW: 23,
      W: 0,
      WNW: 340,
      calm: 0,
    };

    const windAngle = directions[windDirection] !== undefined ? directions[windDirection] * (Math.PI / 180) : null;
    if (windAngle > 360) {
      return windAngle - 360;
    }

    return windAngle;
  };

  const getRotationAngle = (windDirection) => {
    const windDirectionMap = {
      'N': require('../../images/windArrows/windN.png'),
      'NNE': require('../../images/windArrows/windNE.png'),
      'NE': require('../../images/windArrows/windNE.png'),
      'ENE': require('../../images/windArrows/windNE.png'),
      'E': require('../../images/windArrows/windE.png'),
      'ESE': require('../../images/windArrows/windSE.png'),
      'SE': require('../../images/windArrows/windSE.png'),
      'SSE': require('../../images/windArrows/windSE.png'),
      'S': require('../../images/windArrows/windS.png'),
      'SSW': require('../../images/windArrows/windSW.png'),
      'SW': require('../../images/windArrows/windSW.png'),
      'WSW': require('../../images/windArrows/windSW.png'),
      'W': require('../../images/windArrows/windW.png'),
      'WNW': require('../../images/windArrows/windNW.png'),
      'NW': require('../../images/windArrows/windNW.png'),
      'NNW': require('../../images/windArrows/windNW.png'),
    };

    // Default rotation angle for unknown wind directions
    const defaultAngle = 0;

    // Convert wind direction to uppercase for case-insensitivity
    if(windDirection === 'calm') return defaultAngle;
    if(windDirection === 'N/A') return defaultAngle;
    if(windDirection === 'Unknown') return defaultAngle;
    if(windDirection === '-') return defaultAngle;
    const normalizedWindDirection = windDirection;
  
    // Get the rotation angle from the map or use the default angle
    return windDirectionMap[normalizedWindDirection] || defaultAngle;
  };  

  const pointSets = [
    {
      id: 'ferryTerminal',
      initialCoordinates: { x: -123.281, y: 48.9514, range: mediumRange },
      color: weatherStation.ferryTerminal.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.ferryTerminal.windDirection[0]) 
    },
    {
      id: 'howeSound',
      initialCoordinates: { x: -123.4362, y: 49.39123, range: mediumRange },
      color: weatherStation.howeSound.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.howeSound.windDirection[0])

  },
    {
      id: 'pointAtkinson',
      initialCoordinates: { x: -123.26, y: 49.2843, range: mediumRange },
      color: weatherStation.pointAtkinson.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.pointAtkinson.windDirection[0])
    },
  //   // wave buoy
    {
      id: 'westSOG',
      initialCoordinates: { x: -123.5437, y: 48.8464, range: mediumRange },
      color: waveBuoy.georgiaStrait.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(waveBuoy.georgiaStrait.windDirection[0])
    },
    {
      id: 'sandheads',
      initialCoordinates: { x: -123.39, y: 49.0244, range: mediumRange },
      color: weatherStation.sandheads.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.sandheads.windDirection[0])
    },
  //   // wave buoy
    {
      id: 'englishBay',
      initialCoordinates: { x: -123.467, y: 49.244, range: mediumRange },
      color: waveBuoy.englishBay.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(waveBuoy.englishBay.windDirection[0]) 
    },
  // // wave buoy
    {
      id: 'halibutBank',
      initialCoordinates: { x: -123.789, y: 49.112, range: mediumRange },
      color: waveBuoy.halibutBank.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(waveBuoy.halibutBank.windDirection[0])
    },
    {
      id: 'entranceIsland',
      initialCoordinates: { x: -123.9069, y: 49.1312, range: mediumRange },
      color: weatherStation.entranceIsland.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.entranceIsland.windDirection[0])
    },
    {
      id: 'ballenasIslands',
      initialCoordinates: { x: -124.132, y: 49.292, range: mediumRange },
      color: weatherStation.ballenasIslands.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.ballenasIslands.windDirection[0])
    },
    {
      id: 'sechelt',
      initialCoordinates: { x: -123.779, y: 49.345, range: mediumRange },
      color: weatherStation.sechelt.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.sechelt.windDirection[0])    
    },
    {
      id: 'sisterIslets',
      initialCoordinates: { x: -124.559, y: 49.328, range: mediumRange },
      color: weatherStation.sisterIslets.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.sisterIslets.windDirection[0])
    },
    {
      id: 'sisterIsletsNorth',
      initialCoordinates: { x: -124.768, y: 49.489, range: mediumRange },
      color: weatherStation.sisterIslets.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.sisterIslets.windDirection[0])
    },
    {
      id: 'griefPoint',
      initialCoordinates: { x: -124.7918, y: 49.7138, range: mediumRange },
      color: weatherStation.griefPoint.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.griefPoint.windDirection[0])
    },
   // wave buoy
    {
      id: 'sentryShoal',
      initialCoordinates: { x: -125.032, y: 49.812, range: mediumRange },
      color: waveBuoy.sentryShoal.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(waveBuoy.sentryShoal.windDirection[0])
    },
    {
      id: 'campbellRiverAirport',
      initialCoordinates: { x: -125.226, y: 49.85166, range: mediumRange },
      color: weatherStation.campbellRiverAirport.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.campbellRiverAirport.windDirection[0])   
    },
    {
      id: 'victoriaIntlAirport',
      initialCoordinates: { x: -123.426, y: 48.4924, range: mediumRange },
      color: weatherStation.victoriaIntlAirport.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.victoriaIntlAirport.windDirection[0])   
    },
    {
      id: 'kelpReefs',
      initialCoordinates: { x: -123.259, y: 48.4273, range: mediumRange },
      color: weatherStation.kelpReefs.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.kelpReefs.windDirection[0])   
    },
    {
      id: 'discoveryIsland',
      initialCoordinates: { x: -123.259, y: 48.2773, range: mediumRange },
      color: weatherStation.discoveryIsland.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.discoveryIsland.windDirection[0])   
    },
    {
      id: 'raceRocksLightstation',
      initialCoordinates: { x: -123.53959, y: 48.1273, range: mediumRange },
      color: weatherStation.raceRocksLightstation.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.raceRocksLightstation.windDirection[0])   
    },
    {
      id: 'raceRocksLightstation2',
      initialCoordinates: { x: -123.93959, y: 48.1273, range: mediumRange },
      color: weatherStation.raceRocksLightstation.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.raceRocksLightstation.windDirection[0])   
    },
    {
      id: 'usaStation2',
      initialCoordinates: { x: -123.15959, y: 48.1473, range: mediumRange },
      color: weatherStation.usaStation2.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.usaStation2.windDirection[0])   
    },
  ];

  const handleSliderChange = (event) => {
    const selectedHour = parseInt(event.target.value, 10);
    setHour(selectedHour);
    setCurrentTime(forecastTimes.current[selectedHour]); // Update the current time display
    updateWeatherData(selectedHour);
    setFileName(s3Files[selectedHour]);
  };

  const updateWeatherData = (forecast) => {
    setFileName(s3Files[forecast]);
    // animateDot();
  };

  const handlePlayButtonClick = () => {
    setPlayForecast(true);
    let index = hour || 0;
    intervalRef.current = setInterval(() => {
      updateWeatherData(index);
      index = (index + 1) % s3Files.length;
      // setHour as the index and have it loop back to 0 when it reaches the end
      setHour(index);
    }, 1900);
  };

  const handlePauseButtonClick = () => {
    setPlayForecast(false);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const changeForecastModel = (newType) => {

    if (forecastType !== newType) {
      setForecastType(newType);
      setS3FolderName(newType === 'wind' ? 'windFiles' : 'waveFiles');
    }
    
  };

  const convertUtcToPst = (fileName) => {
  
    // Extract the date and time from the filename
    const fileNameParts = fileName.split('/');
    const baseName = fileNameParts[fileNameParts.length - 1]; // Get the actual filename
    const dateStr = baseName.split('_')[0]; // "20241215T18"
    const hoursOffsetStr = baseName.split('_').pop().replace('.geojson', ''); // "001"
    const hoursOffset = parseInt(hoursOffsetStr, 10); // Convert to integer
  
    // Parse the extracted date into a Date object (UTC)
    const reportDateUtc = new Date(
      Date.UTC(
        parseInt(dateStr.substring(0, 4)), // Year
        parseInt(dateStr.substring(4, 6)) - 1, // Month (0-based)
        parseInt(dateStr.substring(6, 8)), // Day
        parseInt(dateStr.substring(9, 11)) // Hour from the file
      )
    );
  
    // Add the hour offset to the UTC time
    reportDateUtc.setHours(reportDateUtc.getHours() + hoursOffset);
  
    // Format the date to PST (UTC-7) and apply your preferred format
    const formattedDate = reportDateUtc.toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles', // Convert to PST
      weekday: 'short', // "Mon"
      hour: 'numeric', // "4"
      minute: 'numeric', // "00"
      hour12: true // "pm"
    }).replace(':00', ''); // Remove ":00" if minutes are zero
  
    return formattedDate;
  };


  // useEffect(() => {

  //   setFileName(s3Files[0]);
    
  // }, [s3Files]);

  useEffect(() => {

      const forecastTime = s3Files.map((file) => {
        return convertUtcToPst(file)
      })

      setForecastTimeArray(forecastTime)

  }, [s3Files])

    useEffect(() => {
      const map = mapRef.current?.getMap();

      if (!map) return;

      const layerId = forecastType === 'wind' ? 'wind-speed' : 'wave-height';

      const handleMouseMove = (e) => {
        if (e.features && e.features.length > 0) {
          const windSpeedData = e.features[0].properties;
          // get coordinates to show mouse position
          const coordinates = e.lngLat;
          setMousePosition({ lng: coordinates.lng, lat: coordinates.lat });
          setWeatherHoverValue(windSpeedData);
        }
      };

      map.on('mousemove', layerId, handleMouseMove);

      return () => {
        map.off('mousemove', layerId, handleMouseMove);
        map.off('mouseenter', layerId);
        map.off('mouseleave', layerId);
      };
    }, [fileName]);
    

    const loadMapData = () => {

      const map = mapRef.current?.getMap();
      if (!map) {
        console.warn("Map is not initialized yet!");
        return;
      }


      try {
        // Remove existing layers and sources to avoid duplicates

        const layers = ['wind-speed', 'wind-direction-data', 'wave-height', 'windSource'];
        
        layers.forEach((layerId) => {
          if (map.getLayer(layerId)) {
            map.removeLayer(layerId);
          }
          if (map.getSource(layerId)) {
            map.removeSource(layerId);
          }
        });

      } catch (error) {
        console.error('Error while removing layers or sources:', error);
      }

      const windLayerConfig = {
        'circle-radius': [
          'interpolate',
          ['exponential', 2],
          ['zoom'],
          4.5, 15,
          5, 22,
          5.5, 26,
          
          6, 32,
          6.25, 36,
          6.5, 42,
          6.75, 49,

          7, 63,
          7.25, 78,
          7.5, 83,
          7.75, 90,

          8, 95,
          8.25, 120,
          8.5, 120,
          8.75, 145,

          9, 175,
          9.25, 205,
          9.5, 235,
          9.75, 275,

          10, 305,
          10.25, 335,
          10.5, 365,
          10.75, 375,

          11, 400,
          11.25, 435,
          11.5, 460,
        ],
        'circle-color': [
            'interpolate',
            ['linear'],
            // CHANGE THIS TO 'w' TO DISPLAY WIND SPEED.. BUT WAS ORIGINALLY 's'.. NEED TO CHECK
            ['get', 's'],
            0, 'rgba(98, 0, 255, 0.1)',    // Color for low wind speed
            2.57, 'rgba(0, 0, 255, 0.1)',   // Color for moderate wind speed
            5.1, 'rgba(0, 140, 0, 0.1)',   // Color for higher wind speed
            7.7, 'rgba(255, 180, 0, 0.1)',   // Color for even higher wind speed
            10.5, 'rgba(255, 139, 0, 0.1)',   // Color for very high wind speed
            12.86, 'rgba(255, 0, 0, 0.1)',   // Color for very high wind speed
            // 15, 'rgba(0,0,0.15)'    // Color for extremely high wind speed
        ],
        'circle-blur': 1 // Add blur to blend circles
      };

      const waveLayerConfig = {
        'circle-radius': [
          'interpolate',
          ['exponential', 2],
          ['zoom'],
          4.5, 20,
          5, 30,
          5.5, 40,
          6, 50,
          6.25, 60,
          6.5, 70,
          6.75, 80,
          7, 90,
          7.25, 100,
          7.5, 115,
          7.75, 120,
          8, 125,
          8.25, 140,
          8.5, 155,
          8.75, 195,
          9, 215,
        ],
        'circle-color': [
          'interpolate',
          ['linear'],
          ['get', 'w'],
          // ['coalesce', ['get', 'w'], 0], // Default to 0 if 'w' is null
          -1, 'grey', // Grey for values < 0
          0, 'rgba(128, 0, 128, 1)', // Purple
          0.15, 'rgba(0, 0, 255, 1)', // Blue 1ft
          0.31, 'rgba(0, 128, 0, 1)', // Green 2ft
          0.47, 'rgba(255, 255, 0, 1)', // Yellow 3ft
          .65, 'rgba(255, 165, 0, 1)', // Orange 4ft
          .95, 'rgba(255, 0, 0, 1)', // Red 6ft
          2.5, 'rgba(195, 0, 0, 1)', // Dark Red 16ft
          4, 'rgba(140, 0, 0, 1)', // Darker Red 25ft
        ],
        'circle-blur': [
          'interpolate',
          ['linear'],
          ['zoom'],
          6, 4, // At zoom level 0, blur is set to 1
          6.5, 3.5, // At zoom level 5, blur is set to 1.5
          7.3, 2.5, // At zoom level 10, blur is set to 15
          8, 2,
        ],
      };

      try {

        const sourceName = forecastType === 'wind' ? 'wind-speed' : 'wave-height';
        const paintConfig = forecastType === 'wind' ? windLayerConfig : waveLayerConfig;

      // Check if the source already exists
      if (mapRef.current.getSource(sourceName)) {
        // Update the existing source if necessary
        console.log(`Source ${sourceName} already exists. Skipping addition.`);
        return;
      }

      console.log('adding file:', fileName)

      map.addSource(sourceName, {
        type: 'vector',
        tiles: [
          `https://sportymbtiles.s3.us-east-1.amazonaws.com/${s3FolderName}/${fileName}/{z}/{x}/{y}.pbf`
        ],
        minzoom: 2,
        maxzoom: 9, // Matches the maxzoom in the tile pbf
      });
      
      map.addLayer({
        id: sourceName, 
        type: 'circle',
        source: sourceName, 
        'source-layer': sourceName,
        minzoom: 2,
        maxzoom: 9,
        paint: paintConfig,
      }, forecastType === 'wave' ? 'ocean-source' : map.getStyle().layers[map.getStyle().layers.length - 1].id); // Add on top

        // map.on('idle', () => {
        //   map.flyTo({
        //     center: [-124.00881, 49.54850], // Target center [lng, lat]
        //     zoom: 6, // Target zoom level
        //     speed: 1.2, // Fly-to speed
        //     curve: 1, // Smoothing factor
        //   });
        // });

      } catch (error) {
        console.error('Error adding sources or layers:', error);
      }

    };  
  
    // TODO: Issue where only the most recent photo is displayed.. need all photos to show (if within 6 hours)
    const retrievePhotosWithinTimeRange = async () => {
      const currentTime = new Date();
      const sixHoursAgo = new Date(currentTime.getTime() - 6 * 60 * 60 * 1000); // 6 hours ago
    
      try {
        // Retrieve a list of all files in the Firebase Storage folder
        const files = await listAll(ref(storage, 'uploads'));
  
        // console.log("files", files)
    
        // Filter files based on their upload date and time
        const filteredFiles = await Promise.all(files.items.map(async (fileRef) => {
          const metadata = await getMetadata(fileRef);
          const uploadTime = new Date(metadata.timeCreated);
    
          // ONLY GRABBING THE FIRST PHOTO THEN RETURNING.. NEED TO GRAB ALL PHOTOS
          // Check if upload time is within 6 hours of current time
          if (uploadTime >= sixHoursAgo && uploadTime <= currentTime) {
            return {
              name: fileRef.name,
              downloadURL: await getDownloadURL(fileRef),
              metadata: metadata,
            };
          } else {
            return null;
          }
        }));
    
        // Remove null entries and return the filtered files
        return filteredFiles.filter((file) => file !== null);
      } catch (error) {
        console.error('Error retrieving photos:', error);
        return [];
      }
    };

    const setWeatherSettingsOpen = () => {
      setShowSettingsDropdown(!showSettingsDropdown)
    }

    const handleUnitChange = (event) => {
      const { name, value } = event.target;
      setWeatherUnits((prevUnits) => ({
          ...prevUnits,
          [name]: value,
      }));
    };
   
    // Update map when the fileName changes
    useEffect(() => {

      if (mapRef.current) {
          loadMapData();
        }

    }, [fileName, forecastType, forecastHour, file, s3Files]);

    useEffect(() => {

      retrievePhotosWithinTimeRange()
        .then((photos) => {
          setPhotoArray(photos);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    
    }, []);

    useEffect(() => {

      const map = mapRef.current?.getMap();
      if (!map) return;
    
      let animationFrameId;
      let originalCoordinates = []; // To store original coordinates of features

      const resetAndAnimate = () => {
        
        const bounds = map.getBounds();

        const buffer = 200000; // Extend bounds
        const expandedBounds = [
          [bounds.getWest() - buffer, bounds.getSouth() - buffer],
          [bounds.getEast() + buffer, bounds.getNorth() + buffer],
        ];

        // NOT DISPLAYING ALL WIND DOTS ON MAP OR IN FEATURE SET
        const features = map.queryRenderedFeatures(expandedBounds, {
          layers: ['wind-speed'],
        });

        // const features = map.querySourceFeatures('wind-direction-data');

        // console.log("features here", features)
    
        if (features.length > 0) {
          // Convert features to GeoJSON
          const geojson = {
            type: 'FeatureCollection',
            features: features.filter((_, index) => index % 2 === 0).map((feature) => {
              const featureData = feature.toJSON();
              originalCoordinates.push([...featureData.geometry.coordinates]); // Store original coordinates
              return featureData;
            }),
          };

          // Update or add the GeoJSON source
          if (map.getSource('wind-direction-data')) {
            map.getSource('wind-direction-data').setData(geojson);
          } else {
            map.addSource('wind-direction-data', {
              type: 'geojson',
              data: geojson,
            });
    
            map.addLayer({
              id: 'windLayer',
              type: 'circle',
              source: 'wind-direction-data',
              paint: {
                'circle-radius': [
                  'interpolate',
                  ['exponential', 2],
                  ['zoom'],
                  4.5, 0.75,
                  8, 2,
                ],
                'circle-color': [
                  'interpolate',
                  ['linear'],
                  ['get', 's'],
                  -1, 'rgba(255,255,255, 0)',
                  0, 'rgba(255,255,255, 0.05)',
                  1.57, 'rgba(255,255,255, 0.05)',
                  2.57, 'rgba(255,255,255, 0.75)',
                  50, 'rgba(255,255,255, 1)',
                ],
              },
            }
          );
        }
          
    
          // Start animation
          animateDots(geojson);
        }
      };
    
      const animateDots = (geojson) => {
        
        const maxDistance = 0.15; // Max distance before resetting to original position
        const speedFactor = 0.0001; // Adjust speed factor as needed
      
        const featuresUpdated = geojson.features.map((feature, index) => {

          const originalLat = originalCoordinates[index][1];
          const originalLng = originalCoordinates[index][0];
    
          const { s, d } = feature.properties;
          const speed = s * speedFactor;

          const radians = ((d + 180) % 360) * (Math.PI / 180);

          const dx = speed * Math.cos(radians) / Math.cos((originalLat * Math.PI) / 180);
          const dy = speed * Math.sin(radians);
    
          feature.geometry.coordinates[1] += dx;
          feature.geometry.coordinates[0] += dy;
    
          const distance = Math.sqrt(
            Math.pow(feature.geometry.coordinates[1] - originalLat, 2) +
            Math.pow(feature.geometry.coordinates[0] - originalLng, 2)
          );
    
          // Reset to original position if distance exceeds max
          if (distance > maxDistance) {
            feature.geometry.coordinates = [
              originalLng + Math.random() * 0.1, // Add slight variation
              originalLat + Math.random() * 0.1,
            ];
          }
    
          return feature;
        });
    
        // Update the source with new coordinates
        if (map.getSource('wind-direction-data')) {
          map.getSource('wind-direction-data').setData({
            type: 'FeatureCollection',
            features: featuresUpdated,
          });
        }

        animationFrameId = requestAnimationFrame(() =>
          animateDots({ ...geojson, features: featuresUpdated })
        );
      };
    
      const onMapIdle = () => {
        if (map.isSourceLoaded('wind-speed')) {
          originalCoordinates = []; // Reset the original coordinates
          resetAndAnimate();
          map.off('idle', onMapIdle); // Cleanup listener once data is retrieved
        }
      };
    
      // Attach the idle event listener
      map.on('idle', onMapIdle);
    
      // Cleanup listener on unmount
      return () => {
        cancelAnimationFrame(animationFrameId);
        map.off('idle', onMapIdle);
      };
    }, [fileName, file, forecastType, mapRef.current]);

    // remove wind-direction-data if forecastType is wave

    useEffect(() => {
      const map = mapRef.current?.getMap();
      if (!map) return;

      if(forecastType === 'wave') {
        if (map.getLayer('windLayer')) {
          map.removeLayer('windLayer');
        }
        if (map.getSource('wind-direction-data')) {
          map.removeSource('wind-direction-data');
        }
      }

    }, [forecastType]);
  
    const analyzeWaveHeights = (waveHeights) => {
  
      const len = waveHeights.length;
      if (len < 2) return "Not enough data to analyze wave heights.";
  
      const latest = waveHeights[0];
      const secondLatest = waveHeights[1];
      const thirdLatest = waveHeights[2];
        
      if (len >= 3 && latest > secondLatest && secondLatest > thirdLatest) {
        return "Waves have been consistently building over the last 3 hours.";
      }
      if (len >= 2 && latest > secondLatest && secondLatest > thirdLatest) {
        return "Waves have been building over the past 2 hours.";
      }
      if (latest > secondLatest) {
        return "Waves are starting to increase.";
      }
      if (len >= 2 && Math.abs(latest - secondLatest) / secondLatest <= 0.05) {
        return "Waves have remained consistent.";
      }
      if (len >= 3 && latest < secondLatest && secondLatest < thirdLatest) {
        return "Waves have been consistently decreasing over the last 3 hours.";
      }
      if (len >= 2 && latest < secondLatest && secondLatest < thirdLatest) {
        return "Waves have been decreasing over the past 2 hours.";
      }
      if (latest < secondLatest) {
        return "Waves are starting to decrease.";
      }
    
      return "Wave heights are fluctuating.";
    };
  
    const analyzeWindSpeeds = (windSpeeds) => {
      const len = windSpeeds.length;
      if (len < 2) return "Not enough data to analyze wind speed.";
    
      const latest = parseFloat(windSpeeds[0]);
      const secondLatest = parseFloat(windSpeeds[1]);
      const thirdLatest = parseFloat(windSpeeds[2]);
    
      if (len >= 3 && latest > secondLatest && secondLatest > thirdLatest) {
        return "The wind has been consistently building over the last 3 hours.";
      }
      if (len >= 2 && latest > secondLatest && secondLatest > thirdLatest) {
        return "The wind has been building over the past 2 hours.";
      }
      if (latest > secondLatest) {
        return "The wind is starting to increase.";
      }
      if (len >= 2 && Math.abs(latest - secondLatest) / secondLatest <= 0.05) {
        return "The wind has remained consistent.";
      }
      if (len >= 3 && latest < secondLatest && secondLatest < thirdLatest) {
        return "The wind has been consistently decreasing over the last 3 hours.";
      }
      if (len >= 2 && latest < secondLatest && secondLatest < thirdLatest) {
        return "The wind has been decreasing over the past 2 hours.";
      }
      if (latest < secondLatest) {
        return "The wind is starting to decrease.";
      }
    
      return "The wind has been fluctuating.";
    };
  
    const categorizeDirection = (direction) => {
      switch (direction) {
        case 'N':
          return 'N';
        case 'NNE':
        case 'NE':
        case 'ENE':
          return 'NE';
        case 'E':
          return 'E';
        case 'ESE':
        case 'SE':
        case 'SSE':
          return 'SE';
        case 'S':
          return 'S';
        case 'SSW':
        case 'SW':
        case 'WSW':
          return 'SW';
        case 'W':
          return 'W';
        case 'WNW':
        case 'NW':
        case 'NNW':
          return 'NW';
        default:
          return 'Unknown';
      }
    };

    const analyzeWindDirections = (windDirections) => {
      const len = windDirections.length;
      if (len < 6) return "Not enough data available.";
    
      const lastSixDirections =[
        windDirections[0],
        windDirections[1],
        windDirections[2],
        windDirections[3],
        windDirections[4],
        windDirections[5],
      ]
      const categorizedDirections = lastSixDirections.map(categorizeDirection);
    
      const directionCounts = categorizedDirections.reduce((acc, dir) => {
        acc[dir] = (acc[dir] || 0) + 1;
        return acc;
      }, {});
    
      const predominantDirection = Object.keys(directionCounts).reduce((a, b) =>
        directionCounts[a] > directionCounts[b] ? a : b
      );
    
      return `Wind has typically been coming from the ${predominantDirection}.`;
    };

    // toggle map layers and save to local storage
    const handleSettingsCheckBoxChange = (event) => {
      const { name, checked } = event.target;
      // if(name === 'windAnimation') handleMapLayer();
      const newSettings = {
        ...weatherCheckboxStatus,
        [name]: checked ? 'on' : 'off',
      }
      setWeatherCheckboxStatus(newSettings);
      localStorage.setItem('weatherCheckboxStatus', JSON.stringify(newSettings));
    };

    const handleWeatherClick = (value) => {  
      // Redirect to the '/weatherTable' page and pass the weatherData as state
      navigate('/weatherTable', { state: value});
    };

  //   const handleMapLayer = () => {

  //     const map = mapRef.current;

  //     const layerId = 'wind-speed';
      
  //     const mapLayer = map.getLayer(layerId);

  //     console.log("Map Layer:", mapLayer);

  //     if (map.getLayer(layerId)) {
  //       map.setLayoutProperty(layerId, 'visibility', 'visible');
  //     }
        
  //   //   let visibility = map.getLayoutProperty('wind-speed', 'visibility');
  
  //   //   if (visibility === undefined) {
  //   //       // Set the initial visibility to 'none' or 'visible' depending on your default
  //   //       visibility = 'visible';
  //   //       map.setLayoutProperty('wind-speed', 'visibility', visibility);
  //   //   }
  
  //   //   if (visibility === 'visible') {
  //   //     map.setLayoutProperty('windLayer', 'visibility', 'none');
  //   //     map.setLayoutProperty('wind-speed', 'visibility', 'none');
  //   //   } else {
  //   //     map.setLayoutProperty('windLayer', 'visibility', 'visible');
  //   //     map.setLayoutProperty('wind-speed', 'visibility', 'visible');
  //   // }
  // }

  const generateChartData = (selectedMarker) => {

    const updateTimes = selectedMarker.updateTime;

    const formattedTimeArray = []

    updateTimes.forEach((data) => {
      const date = new Date(data);
      const formattedTime = date.toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          timeZone: 'America/Los_Angeles',
      });

      formattedTimeArray.push(formattedTime);

    });

    // Reverse the array to display the most recent data first
    formattedTimeArray.reverse();

    // Reverse the order of the data array (if needed)
    const reversedData = selectedMarker.waveHeight
    ? [...selectedMarker.waveHeight].reverse()
    : [...selectedMarker.windSpeed].reverse();
  
      const data = {
        labels: formattedTimeArray,
        datasets: [
          {
            label: selectedMarker.waveHeight ? 'Wave Height' :'Wind Speed',
            data: reversedData,
            fill: true,
            tension: 0.4,
            backgroundColor: 'rgba(255,165,0,.6)',
            borderColor: 'rgba(0,0,255,.35)',
          },
        ],
      };

      return data

  };

  const findClosestForecastIndex = (filesArray) => {

    // Step 1: Get the current PST time
    const currentPstDate = new Date();
    
    // Step 2: Convert PST to UTC
    const utcOffset = 7 * 60 * 60 * 1000; // 8 hours in milliseconds
    const currentUtcDate = new Date(currentPstDate.getTime() + utcOffset);
  
    // Step 3: Iterate through the array to find the closest forecast hour
    let closestIndex = 0;
    let smallestDifference = Infinity;
  
    filesArray.forEach((file, index) => {
      const fileName = file;
  
      // Extract the date and time from the filename
      const dateStr = fileName.split('_')[0]; // "20240820T06"
      const hoursOffsetStr = fileName.split('_').pop().replace('.geojson', ''); // "001"
      const hoursOffset = parseInt(hoursOffsetStr, 10); // Convert to integer
  
      // Parse the extracted date into a Date object (UTC)
      const fileDateUtc = new Date(
        Date.UTC(
          parseInt(dateStr.substring(0, 4)), // Year
          parseInt(dateStr.substring(4, 6)) - 1, // Month (0-based)
          parseInt(dateStr.substring(6, 8)), // Day
          parseInt(dateStr.substring(9, 11)) // Hour
        )
      );
  
      // Add the hours offset to the UTC time
      fileDateUtc.setHours(fileDateUtc.getHours() + hoursOffset);
  
      // Calculate the difference in time
      const timeDifference = Math.abs(currentUtcDate - fileDateUtc);
  
      // Check if this is the closest time so far
      if (timeDifference < smallestDifference) {
        smallestDifference = timeDifference;
        closestIndex = index;
      }
    });
  
    // Step 4: Return the index of the closest forecast hour
    return closestIndex;
  };

  useEffect(() => {

    weatherDataRef.current = s3Files

  }, [s3Files]);

  useEffect(() => {

    if (weatherDataRef.current?.length > 1) {
      const hourIndex = findClosestForecastIndex(weatherDataRef.current);
      const hourLocal = hourIndex - 7;
      setHour(hourLocal);
      setFileName(weatherDataRef.current[hourLocal]);
    }
    

  }, [weatherDataRef.current]);

  // ################################


    
  return (

    <div>
      <ReactMapGl
        ref={mapRef}
        mapboxAccessToken={MAPBOX_API_KEY}
        onLoad={loadMapData}
        onZoom={(e) => { 
          setZoomLevel(e.viewState.zoom)
          console.log("zoom level: ", e.viewState.zoom)
        }}
        initialViewState={{
          latitude: 49.54850,
          longitude: -124.00881,
          zoom: 5,
        }}
        minZoom={6}
        maxZoom={8.8}
        style={{ width: '100vw', height: '100vh' }}
        mapStyle="mapbox://styles/jcon12/clx8bi8se007s01mwfnl36y68"
      >
      {render && userLocation.lat !== 0 && userLocation.lng !== 0 && window.google && window.google.maps &&
      <Marker
        position={{ lat: userLocation.lat, lng: userLocation.lng }}
        icon={{
          url: require('../../images/userDot.png'),
          scaledSize: new window.google.maps.Size(15, 15)
          }}
          style={{ zIndex: 9999999 }}
      />}
      {mousePosition && (
        <Marker
          latitude={mousePosition.lat}
          longitude={mousePosition.lng}
        >
        {(weatherHoverValue?.s || weatherHoverValue?.w) && (
          <WindSpeedWindow 
              forecastValue={Math.round(
                  forecastType === 'wind' 
                      ? weatherHoverValue?.s * 2.237 
                      : weatherHoverValue?.w * 3.281 * 2
              )}
              windDirection={weatherHoverValue?.d}
              forecastType={forecastType}
          />
          )}
          <div style={{ position: 'relative', width: '40px', height: '40px' }}>
            {/* Horizontal Line */}
            <div style={{
              position: 'absolute',
              top: '50%',
              left: 0,
              width: '100%',
              height: '2px',
              backgroundColor: 'white',
              transform: 'translateY(-50%)'
            }} />
            {/* Vertical Line */}
            <div style={{
              position: 'absolute',
              top: 0,
              left: '50%',
              width: '2px',
              height: '100%',
              backgroundColor: 'white',
              transform: 'translateX(-50%)'
            }} />
          </div>
        </Marker>
      )}
      {mobile ? <BottomNav /> : <SidebarPro />}
      <WeatherLayerCheckBox weatherCheckboxStatus={weatherCheckboxStatus} handleSettingsCheckBoxChange={handleSettingsCheckBoxChange} />
      <WeatherSettingsBtn setWeatherSettingsOpen={setWeatherSettingsOpen} />
      {showSettingsDropdown &&
        <WeatherMapUnits setShowSettingsDropdown={setShowSettingsDropdown} showSettingsDropdown={showSettingsDropdown} handleUnitChange={handleUnitChange} weatherUnits={weatherUnits} />
      }
      <button className='animate__bounceIn weather-slide-btn' onClick={() => setSidePanel({ isPaneOpen: true })}>
        Weather Summary
      </button>
      <SlidingPane
        overlayClassName="weather-slider"
        isOpen={sidePanel.isPaneOpen}
        title="Wave Buoy Weather Summary"
        // subtitle="Optional subtitle."
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setSidePanel({ isPaneOpen: false });
        }}
      > 
      {waveBuoy && Object.keys(waveBuoy).map(buoyKey => {
        return(

          <div className="weather-forecast-container">
            <div>
              <h3 className='mobile-wrap'>{waveBuoy[buoyKey].name}</h3>
              <p>{analyzeWaveHeights(waveBuoy[buoyKey].waveHeight)}</p>
              <p>{analyzeWindSpeeds(waveBuoy[buoyKey].windSpeed)}</p>
              <p>{analyzeWindDirections(waveBuoy[buoyKey].windDirection)}</p>
            </div>
          </div>
        )
      })
      }  
      </SlidingPane>
      {weatherCheckboxStatus.windSpeed === 'on' &&
        Object.values(weatherStation).map((station, index) => {

          let showBasedOnZoom = true

          if(zoomLevel < 5) {
            showBasedOnZoom = false
          } else if(station.zoomLevel === 'off' && zoomLevel < 6) {
            showBasedOnZoom = false
          } else if(station.zoomLevel === 'low' && zoomLevel < 7.3) {
            showBasedOnZoom = false
          } else if(station.zoomLevel === 'medium' && zoomLevel < 7.7) {
            showBasedOnZoom = false
          } else if(station.zoomLevel === 'high' && zoomLevel < 9) {
            showBasedOnZoom = false
          }

          if(showBasedOnZoom === false) {
            return null
          }

          const windSpeed = station.windSpeed[0]
          const windDirection = station.windDirection[0]
          const windDirectionAngle = getRotationAngle(windDirection);

          if(isNaN(windSpeed) && windSpeed !== 'calm') {
            return null;
          }

          return (
            <Marker
              key={index}
              latitude={station.coordinates.lat}
              longitude={station.coordinates.lng}
            >
              <div 
                onClick={() => setSelectedMarker(station)}
                className={station.windSpeed[0].toString().length < 3 ? 'wind-speed-label' : 'wind-speed-label-small-text'}
              >
                {convertUnitsUserSettings(station.windSpeed[0].toString(), weatherUnits.windSpeed)}
              </div>
              {station.windSpeed[0].toString() === 'calm' ? '' : 
              <img 
                className='weather-arrow-icon' 
                onClick={() => setSelectedMarker(station)}
                src={windSpeed > 0 ? windDirectionAngle : require('../../images/windArrows/windN.png')} width={22} height={22}                 
              />}
            </Marker>
          )
        })
      }  
      {weatherCheckboxStatus.waveHeight === 'on' &&
        Object.values(waveBuoy).map((buoy, index) => {

        if (isNaN(buoy.waveHeight[0]) && buoy.waveHeight[0] !== 'calm') {
          return null;
        }

        const waveHeight = buoy.waveHeight[0]
        const waveHeight2 = buoy.waveHeight[1]
        const waveHeightFixed = weatherUnits.waveHeight === 'feet' ? 
        parseFloat(waveHeight) < 10 ? 
        parseFloat(waveHeight).toFixed(1) : 
        parseFloat(waveHeight).toFixed(0) : 
        parseFloat(waveHeight).toFixed(1) ? 
        parseFloat(waveHeight).toFixed(2) :
        parseFloat(waveHeight).toFixed(1);

        const waveHeightDirection = waveHeight > waveHeight2 ? require('../../images/windArrows/waveUp.png') : require('../../images/windArrows/waveDown.png');

          return (
            <Marker
              key={index}
              latitude={buoy.coordinates.lat}
              longitude={buoy.coordinates.lng}
            >
              <div 
                className='wave-height-label'
                onClick={() => setSelectedMarker(buoy)}
              >
                {convertUnitsUserSettings(buoy.waveHeight[0], weatherUnits.waveHeight)}
              </div>
              <img 
                className='weather-arrow-icon' 
                onClick={() => setSelectedMarker(buoy)}
                src={waveHeightDirection} width={14} height={14}                 
              />
            </Marker>
          )
        })
      } 
      <>
        <div className='forecast-hour'>
          <p>{forecastTimeArray[hour]}</p>
        </div>
        <PopupSelector changeForecastModel={changeForecastModel} forecastType={forecastType} />
      </>
      {weatherCheckboxStatus.photos === 'on' &&  
        photoArray.map((photo, index) => {
          return (
            <Marker
              key={index}
              latitude={parseFloat(photo.metadata.customMetadata.latitude)}
              longitude={parseFloat(photo.metadata.customMetadata.longitude)}
              onClick={() => {
                setSelectedPhoto(photo);
              }}
            >
              <img src={photo.downloadURL} alt="weather image" style={{width: '16px', height: '16px'}} />
            </Marker>
          );              
        })
      }
      {selectedPhoto && !showFullSizePhoto &&
      <Popup
        longitude={selectedPhoto.metadata.customMetadata.longitude} 
        latitude={selectedPhoto.metadata.customMetadata.latitude}
        anchor="bottom"
        closeOnClick={false} 
        onClose={() => {
          setSelectedPhoto(null);
        }}
      >
        <div style={{cursor: 'pointer'}} onClick={() => setShowFullSizePhoto(true)}> 
          <img src={selectedPhoto.downloadURL} alt="Photo" style={{ maxWidth: '100%', maxHeight: '400px' }} />
          <h4 style={{textAlign: 'center', margin: 3, padding: 0}}>{transformTime(selectedPhoto.metadata.timeCreated)}</h4>
        </div>
      </Popup>
      }
      {selectedPhoto && showFullSizePhoto &&
      <ModalCustom 
        title='Weather Station Information'
        onClick={
          () => {
            setShowFullSizePhoto(false)
            setSelectedPhoto(null)
          }}
      >
          <img src={selectedPhoto.downloadURL} altFaPauseCircle="Photo" style={{ marginTop: -20, maxWidth: '100%', maxHeight: '100%' }} />
      </ModalCustom>
      }
      {selectedMarker && (
        <Popup 
          longitude={selectedMarker.coordinates.lng} 
          latitude={selectedMarker.coordinates.lat}
          anchor="bottom"
          closeOnClick={false} 
          onClose={() => setSelectedMarker(null)}
          >
          {selectedMarker.type === 'weatherStation' ? 
          <div className='weather-popup'>
            <div
              onClick={() => handleWeatherClick('wind')}
            >
              <FiWind className='weather-icon' size={25} color='black' />
            </div>
            <h3>{selectedMarker.name}</h3>
            <p>Wind Speed: {convertUnitsUserSettings(selectedMarker.windSpeed[0], weatherUnits.windSpeed)}</p>
            <p>Wind Direction: {selectedMarker.windDirection[0]}</p>
            <p>Update Time: {transformTime(selectedMarker.updateTime[0])}</p>
            <div className='line-chart-container'>
              <Line data={generateChartData(selectedMarker)} options={{
                scales: {
                  y: {
                    beginAtZero: true,
                    suggestedMax: selectedMarker.windSpeed[0] + 3,
                  }, 
                  x: {
                    display: false,
                  }
                },
                plugins: {
                legend: {
                  display: false
                  }
                },
              }} />
            </div>
          </div> : 
          <div className='weather-popup'>
            <div
              onClick={() => handleWeatherClick('wave')}
            >
              <GiBigWave className='weather-icon' size={25} color='black' />
            </div>
            <h3>{selectedMarker.name}</h3>
            <p>Wave Height: {convertUnitsUserSettings(selectedMarker.waveHeight[0], weatherUnits.waveHeight) + ` ` + weatherUnits.waveHeight}</p>                  
            <p>Wind Speed: {convertUnitsUserSettings(selectedMarker.windSpeed[0], weatherUnits.windSpeed) + ` ` + weatherUnits.windSpeed}</p>
            <p>Wind Direction: {selectedMarker.windDirection[0]}</p>
            <p>Update Time: {transformTime(selectedMarker.updateTime[0])}</p>
            <div className='line-chart-container'>
              <Line data={generateChartData(selectedMarker)} options={{
                scales: {
                  y: {
                    beginAtZero: true,
                    suggestedMax: selectedMarker.waveHeight[0] + 3,
                  }, 
                  x: {
                    display: false,
                  }
                },
                plugins: {
                legend: {
                  display: false
                  }
                },
              }} />
            </div>
          </div>}
        </Popup>)}
        {weatherCheckboxStatus.waveHeight === 'on' &&
          Object.values(waveBuoy).map((buoy, index) => {

          if (isNaN(buoy.waveHeight[0]) && buoy.waveHeight[0] !== 'calm') {
            return null;
          }

          const waveHeight = buoy.waveHeight[0]
          const waveHeight2 = buoy.waveHeight[1]
          const waveHeightFixed = weatherUnits.waveHeight === 'feet' ? 
          parseFloat(waveHeight) < 10 ? 
          parseFloat(waveHeight).toFixed(1) : 
          parseFloat(waveHeight).toFixed(0) : 
          parseFloat(waveHeight).toFixed(1) ? 
          parseFloat(waveHeight).toFixed(2) :
          parseFloat(waveHeight).toFixed(1);

          const waveHeightDirection = waveHeight > waveHeight2 ? require('../../images/windArrows/waveUp.png') : require('../../images/windArrows/waveDown.png');

            return (
              <Marker
                key={index}
                latitude={buoy.coordinates.lat}
                longitude={buoy.coordinates.lng}
              >
                <div 
                  className='wave-height-label'
                  onClick={() => setSelectedMarker(buoy)}
                >
                  {convertUnitsUserSettings(buoy.waveHeight[0], weatherUnits.waveHeight)}
                </div>
                <img 
                  className='weather-arrow-icon' 
                  onClick={() => setSelectedMarker(buoy)}
                  src={waveHeightDirection} width={14} height={14}                 
                />
              </Marker>
            )
        })
        }
        {weatherCheckboxStatus.webCams === 'on' &&
          newWebCamArray.map((webcam, index) => {

            let showBasedOnZoom = true

            if(zoomLevel < 5) {
              showBasedOnZoom = false
            } else if(zoomLevel < 6.3 && webcam.zoomLevel !== 'off') {
              showBasedOnZoom = false
            } else if(zoomLevel < 7.6 && webcam.zoomLevel === 'low') {
              showBasedOnZoom = false
            }

            if(showBasedOnZoom === false) {
              return null
            }

            return (

            <Marker
              key={index}
              latitude={webcam.coordinates.lat}
              longitude={webcam.coordinates.lng}
              onClick={() => setSelectedWebcam(webcam)}
            >
              <img src={require('../../images/webcamPurple.png')} alt="webcam" style={{width: '16px', height: '16px'}} />
            </Marker>
              )
            }
          )
        }
        {selectedWebcam && 
          <div className={selectedWebcam.className[0]}>
            <SimpleCloseBtn onClick={() => setSelectedWebcam(null)} />
            {selectedWebcam.img ? 
            <img src={selectedWebcam.url} alt="Webcam" className={selectedWebcam.className[3]} />
            :
            <iframe
                title="Webcam Viewer"
                className={selectedWebcam.className[1]}
                src={selectedWebcam.url}
                width="100%"
                height="100%"
                scrolling='no'
            />
            }
            <div className={selectedWebcam.className[2]}></div>
        </div>
        } 
      <>
        <ForecastSlider 
          forecastTimeArray={forecastTimeArray} 
          currentTime={currentTime} 
          playForecast={playForecast} 
          handlePlayButtonClick={handlePlayButtonClick} 
          handlePauseButtonClick={handlePauseButtonClick} 
          handleSliderChange={handleSliderChange} 
          // forecastTimes={forecastTimes} 
          hour={hour}           
        />
      </>
      <WindSpeedChart forecastModel={forecastType} />
    </ReactMapGl>
  </div>
  )};

export default WeatherMapBoxTileSet;
