import React, { useState, useRef, useEffect, useContext } from 'react';
import { GoogleMap, Marker, InfoWindowF, InfoWindow, LoadScript, Polyline, Polygon } from '@react-google-maps/api';
import axios from 'axios';
import { transformKey } from '../../functions/transformKey';
import SidebarPro from '../global/sidebar';
import BottomNav from '../global/bottomNav';
import Modal from '../global/modalLarge';
import AreaSearchSpeciesSelection from '../areaSearch/areaSearchSpeciesSelection';
import { FaSearch } from 'react-icons/fa';
import { Context } from '../context/context';
import Footer from '../global/footer';

function AreaStatus() {

  const [fishingAreaDataArray, setFishingAreaData] = useState([]);
  const [fishingSubAreasArray, setFishingSubAreasArray] = useState([]);

  useEffect(() => {
    import('../../fishingData/fishingAreas')
        .then((module) => setFishingAreaData(module.fishingAreaDataArray))
        .catch((err) => console.error("Error loading fishing area data:", err));
    
    import('../../fishingData/fishingSubAreas')
        .then((module) => setFishingSubAreasArray(module.fishingSubAreasArray))
        .catch((err) => console.error("Error loading fishing sub area data:", err)
  )
}, []);

    const userLocation = useContext(Context).userLocation;

    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const mapId = '153fb55674dee1df';
    const libraries = ['places'];
    const mapRef = useRef(null); // Reference to Google Map component
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [zoomLevel, setZoomLevel] = useState(7);
    const [render, setRender] = useState(false);
    const [mapCenter, setMapCenter] = useState({ lat: 51, lng: -131 });
    const [mobile, setMobile] = useState(window.innerWidth < 600);
    const [areaSearchResults, setAreaSearchResults] = useState([]);
    const [subAreaRegsData, setSubAreaRegsData] = useState([]);
    const [selectedSpeciesName, setSelectedSpeciesName] = useState('');
    const [hoveredFishingArea, setHoveredFishingArea] = useState(null);

    const onLoad = (map) => {
      map.addListener('zoom_changed', () => {
        setZoomLevel(map.zoom)
      });
    }

    useEffect(() => {
      const fetchFishingSubAreaData = async () => {
          try {
              const response = await axios.get(API_BASE_URL + `/getFishingSubAreaRegulations`);
              const results = response.data.rows;
              results.map((area) => {
                console.log("area: ", area)
                  const subArea = fishingSubAreasArray.find((subArea) => subArea.sub_area_id.toString() === area.sub_area_id.toString());
                  if (subArea) {
                      console.log("subArea.area_label", subArea.area_label)
                      area.sub_area_label = subArea.area_label;
                  }
              });
              setSubAreaRegsData(results);
              console.log(results);
          } catch (error) {
              console.log('Error fetching data:', error);
          }
      };
      fetchFishingSubAreaData();
  }, []);
  

    const submitSearchAreaData = () => {

        axios.get(API_BASE_URL + '/subAreaSearchApp', {
            params: {
                speciesName: selectedSpeciesName,
                status: 'open',
            }
            })
            .then((response) => {
                setAreaSearchResults(response.data.rows);
                setZoomLevel(6);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        if(selectedSpeciesName) {
            submitSearchAreaData();
        }
        setRender(true);
    }
    , [selectedSpeciesName]);

    const MapLegend = () => {

      return (
        
        <div className='searched-info-container'>
          <h4>{transformKey(selectedSpeciesName)}</h4>
          <div><span className='map-legend-square blue-square'></span><p> Open</p></div>
          <div><span className='map-legend-square red-square'></span><p> Closed</p></div>
          <div><span className='map-legend-square orange-square'></span><p> Partial Opening</p></div>
          <div 
              className='area-status-search-again'
              onClick={() => {
                  setAreaSearchResults([]);
                  setSelectedSpeciesName('');
                  setZoomLevel(7);
              }
              }
          >
              <FaSearch color='black' size={20} />
          </div>
        </div>
      );
    }

  return (
    <div className="area-status-page">
      <LoadScript googleMapsApiKey={apiKey} libraries={libraries} mapIds={[mapId]}>
      {mobile ? <BottomNav /> : <SidebarPro />}
      {areaSearchResults?.length === 0 && 
      <Modal showCloseBtn={true}>
        <AreaSearchSpeciesSelection setSelectedSpeciesName={setSelectedSpeciesName} />        
      </Modal>
      }
      {selectedSpeciesName && (areaSearchResults?.length > 0 || areaSearchResults === undefined) &&
        <MapLegend />
      }
      {hoveredFishingArea?.length > 1 && areaSearchResults && <div className='hovered-fishing-area'>Area: {hoveredFishingArea}</div>}
      <GoogleMap
        ref={mapRef}
        mapContainerStyle={{ height: '100vh', width: '100vw' }}
        center={mapCenter} // Use user's location if available, otherwise use default center
        zoom={zoomLevel}
        onLoad={onLoad}
        options={
          {
            fullscreenControl: false,
            mapTypeControl: false, 
            mapId: mapId 
          }
          }
        >
          {render && userLocation.lat !== 0 && userLocation.lng !== 0 && window.google && window.google.maps &&
            <Marker
              position={{ lat: userLocation.lat, lng: userLocation.lng }}
              icon={{
                url: require('../../images/userDot.png'),
                scaledSize: new window.google.maps.Size(15, 15)
                }}
            />          
          }
          {render && areaSearchResults ?
            fishingAreaDataArray.map((fishingArea, index) => {

                if(fishingArea?.properties.LABEL.length > 1) {
                
                return (
                    <Polygon
                        key={index}
                        path={fishingArea.coordinates}
                        options={{
                            fillColor: areaSearchResults?.length > 0 ? 
                            subAreaRegsData.some((area) => area.sub_area_label === fishingArea.properties.LABEL && area.status.includes('closed')) ? 'rgba(255,0,0,.3)' :
                            subAreaRegsData.some((area) => area.sub_area_label === fishingArea.properties.LABEL && area.status.includes('partial')) ? 'rgba(255,165,0,.7)' :
                            areaSearchResults.some((area) => area.area_label === fishingArea.properties.LABEL) ? 'rgba(0,0,255,.3)' : 
                            'rgba(255,0,0,.4)' : 
                            'rgba(0,0,0,0)',
                            fillOpacity: 1,
                            strokeColor: 'rgba(255,255,255,0.65)',
                            strokeOpacity: 1,
                            strokeWeight: .5
                        }}
                        onMouseOver={() => {
                            setHoveredFishingArea(fishingArea.properties.LABEL);
                        }
                        }
                        onMouseOut={() => {
                            setHoveredFishingArea(null);
                        }
                        }
                    />
                );
            } else {
                return null;
            }
          }):
          <div className='area-status-no-results'>
            <h3>No Results...</h3>
          </div>
          }
          {/* {render && zoomLevel > 8 &&
            fishingAreaDataArray.map((fishingArea, index) => {

                return (

                  <Marker
                    key={index}
                    position={fishingArea.centerPoint}
                    icon={{
                        url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
                        scaledSize: new window.google.maps.Size(0, 0)
                    }}
                    label={{
                      text: fishingArea.properties.LABEL,
                      className: 'fishing-area-label',
                      color: 'white',
                      fontWeight: '800',
                    }}
                  />


                );
              } 
            )} */}
        </GoogleMap>
      </LoadScript>
      <Footer />
    </div>
  );
}

export default AreaStatus;
