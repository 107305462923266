import React, { useState, useRef, useEffect, useContext } from 'react';
import { GoogleMap, Marker, InfoWindowF, InfoWindow, LoadScript, Polyline, Polygon } from '@react-google-maps/api';
import SidebarPro from '../global/sidebar';
import BottomNav from '../global/bottomNav';
import { Context } from '../context/context';
import Footer from '../global/footer';

const FishingAreaMap = () => {

  const [fishingAreaData, setFishingAreaData] = useState(null);

  useEffect(() => {
      import('../../fishingData/fishingAreas')
          .then((module) => setFishingAreaData(module.fishingAreaDataArray)) // Access named export
          .catch((err) => console.error("Error loading fishing area data:", err));
  }, []);

    const userLocation = useContext(Context).userLocation;

    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const mapId = '153fb55674dee1df';
    const libraries = ['places'];
    const mapRef = useRef(null); // Reference to Google Map component
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [zoomLevel, setZoomLevel] = useState(5.5);
    const [render, setRender] = useState(false);
    const [mobile, setMobile] = useState(window.innerWidth < 600);
    const [selectedArea, setSelectedArea] = useState(null);
    const [uniqueManagementAreas, setUniqueManagementAreas] = useState([]);
    const [highlightedPolygons, setHighlightedPolygons] = useState([]);

    const onLoad = (map) => {
      map.addListener('zoom_changed', () => {
        setZoomLevel(map.zoom)
      });
    }

    useEffect(() => {
        setRender(true);
        const uniqueManagementAreasSet = [...new Set(fishingAreaData?.map(item => item.properties.MANAGEMENT_AREA))];
        uniqueManagementAreasSet.sort((a, b) => a - b);
        // remove first element of array as it is a 0 and there is no fishing area with a management area of 0
        uniqueManagementAreasSet.shift();
        setUniqueManagementAreas(uniqueManagementAreasSet);
    }, [fishingAreaData]);

    const getPolygonCenter = (polygon) => {
      // Calculate the centroid of the polygon's coordinates
      let latSum = 0;
      let lngSum = 0;
      polygon.coordinates.forEach(coord => {
          latSum += coord.lat;
          lngSum += coord.lng;
      });
      const latCenter = latSum / polygon.coordinates.length;
      const lngCenter = lngSum / polygon.coordinates.length;
      return { lat: latCenter, lng: lngCenter };
  };

  useEffect(() => {
    if (selectedArea) {
        const polygons = fishingAreaData.filter(fishingArea => fishingArea.properties.MANAGEMENT_AREA === selectedArea);
        setHighlightedPolygons(polygons);
    } else {
        setHighlightedPolygons([]);
    }
  }, [selectedArea]);

  const getMapCenter = () => {
      // Calculate the center point of all highlighted polygons
      if (highlightedPolygons.length === 0) {
          return { lat: 51, lng: -128 }; // Default center if no polygons are highlighted
      }
      const centers = highlightedPolygons.map(polygon => getPolygonCenter(polygon));
      const latSum = centers.reduce((sum, center) => sum + center.lat, 0);
      const lngSum = centers.reduce((sum, center) => sum + center.lng, 0);
      const latCenter = latSum / highlightedPolygons.length;
      const lngCenter = lngSum / highlightedPolygons.length;
      return { lat: latCenter, lng: lngCenter };
  };
    
  return (
    <div className="fishing-area-map">
      <LoadScript googleMapsApiKey={apiKey} libraries={libraries} mapIds={[mapId]}>
      {mobile ? <BottomNav /> : <SidebarPro />}
        <div className='area-search-container'>
          {uniqueManagementAreas &&
            uniqueManagementAreas.map((managementArea, index) => (
              <div 
                className='area-search-tile'
                key={index}
                style={{ backgroundColor: selectedArea == managementArea && 'rgba(29, 29, 29,.8)'}}
                onClick={() => {
                  setSelectedArea(managementArea)
                }
                }  
              >
                {managementArea}
              </div>
            ))
          }
        </div>
        <GoogleMap
          ref={mapRef}
          mapContainerStyle={{ height: '100vh', width: '100vw' }}
          // center={mapCenter} // Use user's location if available, otherwise use default center
          zoom={zoomLevel}
          center={getMapCenter()}
          onLoad={onLoad}
          //   onBoundsChanged={onBoundsChanged}
          options={
            {
              fullscreenControl: false,
              mapTypeControl: false, 
              mapId: mapId 
            }
            }
          >
            {render && userLocation.lat !== 0 && userLocation.lng !== 0 && window.google && window.google.maps &&
              <Marker
                position={{ lat: userLocation.lat, lng: userLocation.lng }}
                icon={{
                  url: require('../../images/userDot.png'),
                  scaledSize: new window.google.maps.Size(15, 15)
                }}
              />
            }
            {render && window.google && window.google.maps &&
              fishingAreaData?.map((fishingArea, index) => {
                if (fishingArea?.properties.LABEL.length > 1) {
                  const isHighlighted = fishingArea.properties.MANAGEMENT_AREA === selectedArea;

              return (
                <React.Fragment key={`area-${index}`}>
                  <Polygon
                    path={fishingArea.coordinates}
                    options={{
                      fillColor: isHighlighted ? 'rgba(251, 165, 3, .5)' : 'rgba(0,0,0,.1)',
                      fillOpacity: 1,
                      strokeColor: 'rgba(255,255,255,0.65)',
                      strokeOpacity: 1,
                      strokeWeight: 0.5,
                      animation: 'pulse-animation 1s infinite',
                    }}
                  />
                  {isHighlighted && (
                    <Marker
                      position={fishingArea.centerPoint}
                      icon={{
                        url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
                        scaledSize: new window.google.maps.Size(0, 0), // hide marker, just need label
                      }}
                      label={{
                        text: fishingArea.properties.LABEL,
                        className: 'fishing-area-label',
                      }}
                    />
                  )}
                </React.Fragment>
              );
            } else {
              return null;
            }
          })}
        </GoogleMap>
      </LoadScript>
      <Footer />
    </div>
  );
}

export default FishingAreaMap;
