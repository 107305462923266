

const WindSpeedChart = ({ forecastModel }) => {

  const valueArray = forecastModel === 'wind' ? [0, 5, 10, 15, 20, 25] : ["", 0, 1, 2, 3, 4, 5, 6, 16, 25];
  const gradientBant = forecastModel === 'wind' ? 'wind-speed-gradient' : 'wave-height-gradient';
  
    return (

        <div className="wind-speed-bar">
        <div className="wind-speed-labels">
          {valueArray.map((value, index) => (
            <span key={index}>
              {value}
            </span>
          ))}
        </div>
        <div className={gradientBant}></div>
      </div>

    )

}

export default WindSpeedChart;