import React, { useEffect, useState } from 'react';
import WeatherMap from './components/pages/weatherMap';
import WeatherMapBox from './components/pages/weatherMapBox';
import DestinationsMap from './components/pages/destinationsMap';
import FishingMap from './components/pages/fishingMap';
import Tides from './components/pages/tides';
import AreaStatus from './components/pages/areaStatus';
import FishingAreaMap from './components/pages/fishingAreaMap';
import WeatherTable from './components/pages/weatherTable';
import WeatherForecast from './components/pages/weatherForecast';
import FishingReport from './components/pages/weeklyFishingReports';
import FishingReportJune10 from './components/pages/weeklyFishingReportsJune10';
import HelpPage from './components/pages/helpPage';
import Feedback from './components/pages/feedback';
import TermsAndConditions from './components/pages/termsAndConditions';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { Context } from './components/context/context';
import axios from 'axios';
import WeatherMapBoxTileSet from './components/pages/weatherMapBoxTileSet';
import ElectricWave from './components/global/windWaveLoader';

function App() {

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  
  const [userLocation, setUserLocation] = useState({ lat: 0, lng: 0 });
  const [loading, setLoading] = useState(true);
  const initialWeatherStationState = {
    ballenasIslands: {
    name: 'Ballenas Islands',
    type: 'weatherStation',
    url: '/landStationBallenasIslands',
    coordinates: { lat: 49.346435, lng: -124.156619 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'medium'
    },
    campbellRiverAirport: {
    name: 'Campbell River Airport',
    type: 'weatherStation',
    url: '/landStationCampbellRiver',
    coordinates: { lat: 49.951592, lng: -125.265284 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'off'
    },
    comoxAirport: {
    name: 'Comox Airport',
    type: 'weatherStation',
    url: '/landStationComoxAirport',
    coordinates: { lat: 49.706722, lng: -124.901936 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'high',
    },
    entranceIsland: {
    name: 'Entrance Island',
    type: 'weatherStation',
    url: '/landStationEntranceIsland',
    coordinates: { lat: 49.229230, lng: -123.809082 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'off',
    },
    ferryTerminal: {
    name: 'Tsawwassen Ferry',
    type: 'weatherStation',
    url: '/landStationFerryTerminal',
    coordinates: { lat: 49.018519, lng: -123.169686 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'off'
    },
    griefPoint: {
    name: 'Grief Point',
    type: 'weatherStation',
    url: '/landStationGriefPoint',
    coordinates: { lat: 49.804271, lng: -124.526152 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'off'
    },
    howeSound: {
    name: 'Howe Sound',
    type: 'weatherStation',
    url: '/landStationHoweSound',
    coordinates: { lat: 49.488123, lng: -123.299373 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'off'
    },
    pointAtkinson: {
    name: 'Point Atkinson',
    type: 'weatherStation',
    url: '/landStationPointAtkinson',
    coordinates: { lat: 49.330365, lng: -123.264753 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'medium'
    },
    sandheads: {
    name: 'Sandheads',
    type: 'weatherStation',
    url: '/landStationSandHeads',
    coordinates: { lat: 49.104885, lng: -123.293518 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'medium'
    },
    sisterIslets: {
    name: "Sister Islets",
    type: 'weatherStation',
    url: '/landStationSisterIslets',
    coordinates: { lat: 49.486329, lng: -124.434674 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'off'
    },
    vancouverAirport: {
    name: 'Vancouver Airport',
    type: 'weatherStation',
    url: '/landStationVancouverAirport',
    coordinates: { lat: 49.200401, lng: -123.209452 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'low'
    },
    vancouverHarbour: {
    name: 'Vancouver Harbour',
    type: 'weatherStation',
    url: '/landStationVancouverHarbour',
    coordinates: { lat: 49.279122, lng: -123.235011 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'low'
    },
    westVancouver: {
    name: 'West Vancouver',
    type: 'weatherStation',
    url: '/landStationWestVancouver',
    coordinates: { lat: 49.327970, lng: -123.168640 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'off'
    },
    discoveryIsland: {
    name: 'Discovery Island',
    type: 'weatherStation',
    url: '/landStationDiscoveryIsland',
    coordinates: { lat: 48.425861, lng: -123.235767 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'medium'
    },
    esquimaltHarbour: {
    name: 'Esquimalt Harbour',
    type: 'weatherStation',
    url: '/landStationEsquimaltHarbour',
    coordinates: { lat: 48.432528, lng: -123.434707 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'medium'
    },
    kelpReefs: {
    name: 'Kelp Reefs',
    type: 'weatherStation',
    url: '/landStationKelpReefs',
    coordinates: { lat: 48.547154, lng: -123.237409 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'low'
    },
    nanaimoAirport: {
    name: 'Nanaimo Airport',
    type: 'weatherStation',
    url: '/landStationNanaimoAirport',
    coordinates: { lat: 49.052975, lng: -123.875056 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'medium'
    },
    raceRocksLightstation: {
    name: "Race Rocks",
    type: 'weatherStation',
    url: '/landStationRaceRocksLightstation',
    coordinates: { lat: 48.298756, lng: -123.532302 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'off'
    },
    sechelt: {
    name: 'Sechelt',
    type: 'weatherStation',
    url: '/landStationSechelt',
    coordinates: { lat: 49.470869, lng: -123.756311 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'off'
    },
    sheringhamPoint: {
    name: 'Sheringham Point',
    type: 'weatherStation',
    url: '/landStationSheringhamPoint',
    coordinates: { lat: 48.376669, lng: -123.920956 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'high',
    },
    squamishAirport: {
    name: 'Squamish Airport',
    type: 'weatherStation',
    url: '/landStationSquamishAirport',
    coordinates: { lat: 49.781937, lng: -123.159819 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'off'
    },
    victoriaGonzales: {
    name: 'Victoria Gonzales',
    type: 'weatherStation',
    url: '/landStationVictoriaGonzales',
    coordinates: { lat: 48.408451, lng: -123.332307 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'low'
    },
    victoriaIntlAirport: {
    name: 'Victoria Airport',
    type: 'weatherStation',
    url: '/landStationVictoriaIntlAirport',
    coordinates: { lat: 48.651414, lng: -123.432940 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'off'
    },
    estevanPoint: {
    name: 'Estevan Point',
    type: 'weatherStation',
    url: '/landStationEstevanPoint',
    coordinates: { lat: 49.383009, lng: -126.545326 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'off'
    },
    tofinoAirport: {
    name: 'Tofino Airport',
    type: 'weatherStation',
    url: '/landStationTofinoAirport',
    coordinates: { lat: 49.072881, lng: -125.769916 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'off'
    },
    solanderPoint: {
    name: 'Solander Point',
    type: 'weatherStation',
    url: '/landStationSolanderPoint',
    coordinates: { lat: 50.113790, lng: -127.916561 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'off'
    },
    sartineIsland: {
    name: 'Sartine Island',
    type: 'weatherStation',
    url: '/landStationSartineIsland',
    coordinates: { lat: 50.816921, lng: -128.912700 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'off'
    },
    herbertIsland: {
    name: 'Herbert Island',
    type: 'weatherStation',
    url: '/landStationHerbertIsland',
    coordinates: { lat: 50.939651, lng: -127.632636 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'off'
    },
    portHardyAirport: {
    name: 'Port Hardy Airport',
    type: 'weatherStation',
    url: '/landStationPortHardyAirport',
    coordinates: { lat: 50.688390, lng: -127.371688 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'high',
    },
    fannyIsland: {
    name: 'Fanny Island',
    type: 'weatherStation',
    url: '/landStationFannyIsland',
    coordinates: { lat: 50.453467, lng: -125.992342 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    zoomLevel: 'off'
    },
    usaStation1: {
    name: 'USA Station 1',
    type: 'weatherStation',
    url: '/landStationUsa1',
    coordinates: { lat: 48.124356, lng: -123.448060 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    websiteStationName: 'PTAW1',
    zoomLevel: 'high',
    },
    usaStation2: {
    name: 'USA Station 2',
    type: 'weatherStation',
    url: '/landStationUsa2',
    coordinates: { lat: 48.322209, lng: -122.822642 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    websiteStationName: 'SISW1',
    zoomLevel: 'off'
    },
    usaStation3: {
    name: 'USA Station 3',
    type: 'weatherStation',
    url: '/landStationUsa3',
    coordinates: { lat: 48.545407, lng: -123.011602 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    websiteStationName: 'FRDW1',
    zoomLevel: 'high',
    },
    usaStation5: {
    name: 'USA Station 5',
    type: 'weatherStation',
    url: '/landStationUsa5',
    coordinates: { lat: 48.367, lng: -124.614 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    websiteStationName: 'NEAW1',
    zoomLevel: 'high',
    },
    usaStation6: {
    name: 'USA Station 6',
    type: 'weatherStation',
    url: '/landStationUsa6',
    coordinates: { lat: 48.493, lng: -124.727 },
    windDirection: ['N/A'],
    windSpeed: ['N/A'],
    updateTime: ['N/A'],
    websiteStationName: '46087',
    zoomLevel: 'high',
    },
  };
  const initialWaveBuoyState = {
    englishBay: {
      'name': "English Bay",
      'url': '/weatherDataEnglishBay',
      'coordinates': {lat: 49.3, lng: -123.36},
      'waveHeight': ['N/A'],
      'wavePeriod': ['N/A'],
      'windSpeed': ['N/A'],
      'windDirection': ['N/A'],
      'gustSpeed': ['N/A'],
      'airTemp': ['N/A'],
      'waterTemp': ['N/A'],
      'barometer': ['N/A'],
      'updateTime': ['N/A'],
      'zoomLevel': 'off'
    },
    georgiaStrait: {
      'name': "Georgia Strait",
      'url': '/weatherDataGeorgiaStrait',
      'coordinates': {lat: 49.025, lng: -123.43},
      'waveHeight': ['N/A'],
      'wavePeriod': ['N/A'],
      'windSpeed': ['N/A'],
      'windDirection': ['N/A'],
      'gustSpeed': ['N/A'],
      'airTemp': ['N/A'],
      'waterTemp': ['N/A'],
      'barometer': ['N/A'],
      'updateTime': ['N/A'],
    },
    sentryShoal: {
      'name': "Sentry Shoal",
      'url': '/weatherDataSentryShoal',
      'coordinates': {lat: 49.92, lng: -125.0},
      'waveHeight': ['N/A'],
      'wavePeriod': ['N/A'],
      'windSpeed': ['N/A'],
      'windDirection': ['N/A'],
      'gustSpeed': ['N/A'],
      'airTemp': ['N/A'],
      'waterTemp': ['N/A'],
      'barometer': ['N/A'],
      'updateTime': ['N/A'],
    },
    halibutBank: {
      'name': "Halibut Bank",
      'url': '/weatherDataHalibutBank',
      'coordinates': {lat: 49.34, lng: -123.72},
      'waveHeight': ['N/A'],
      'wavePeriod': ['N/A'],
      'windSpeed': ['N/A'],
      'windDirection': ['N/A'],
      'gustSpeed': ['N/A'],
      'airTemp': ['N/A'],
      'waterTemp': ['N/A'],
      'barometer': ['N/A'],
      'updateTime': ['N/A'],
    },
    laPerouseBank: {
      'name': "La Perouse Bank",
      'url': '/weatherDataLaPerouseBank',
      'coordinates': {lat: 48.840, lng: -126.0},
      'waveHeight': ['N/A'],
      'wavePeriod': ['N/A'],
      'windSpeed': ['N/A'],
      'windDirection': ['N/A'],
      'gustSpeed': ['N/A'],
      'airTemp': ['N/A'],
      'waterTemp': ['N/A'],
      'barometer': ['N/A'],
      'updateTime': ['N/A'],
    },
    southernSOG: {
      'name': "Southern Strait of Georgia",
      'url': '/landStationUsa4',
      'coordinates': {lat: 48.332, lng: -123.179},
      'waveHeight': ['N/A'],
      'wavePeriod': ['N/A'],
      'windSpeed': ['N/A'],
      'windDirection': ['N/A'],
      'gustSpeed': ['N/A'],
      'airTemp': ['N/A'],
      'waterTemp': ['N/A'],
      'barometer': ['N/A'],
      'updateTime': ['N/A'],
    },
    southBrooks: {
      'name': "South Brooks",
      'url': '/weatherDataSouthBrooks',
      'coordinates': {lat: 49.46, lng: -127.53},
      'waveHeight': ['N/A'],
      'wavePeriod': ['N/A'],
      'windSpeed': ['N/A'],
      'windDirection': ['N/A'],
      'gustSpeed': ['N/A'],
      'airTemp': ['N/A'],
      'waterTemp': ['N/A'],
      'barometer': ['N/A'],
      'updateTime': ['N/A'],
    },
    westSeaOtter: {
      'name': "West Sea Otter",
      'url': '/weatherDataWestSeaOtter',
      'coordinates': {lat: 51.4, lng: -128.69},
      'waveHeight': ['N/A'],
      'wavePeriod': ['N/A'],
      'windSpeed': ['N/A'],
      'windDirection': ['N/A'],
      'gustSpeed': ['N/A'],
      'airTemp': ['N/A'],
      'waterTemp': ['N/A'],
      'barometer': ['N/A'],
      'updateTime': ['N/A'],
    },
    eastDellwood: {
      'name': "East Dellwood",
      'url': '/weatherDataEastDellwood',
      'coordinates': {lat: 51.433658, lng: -130.816110},
      'waveHeight': ['N/A'],
      'wavePeriod': ['N/A'],
      'windSpeed': ['N/A'],
      'windDirection': ['N/A'],
      'gustSpeed': ['N/A'],
      'airTemp': ['N/A'],
      'waterTemp': ['N/A'],
      'barometer': ['N/A'],
      'updateTime': ['N/A'],
    },
    southNomad: {
      'name': "South Nomad",
      'url': '/weatherDataSouthNomad',
      'coordinates': {lat: 48.35, lng: -133.95},
      'waveHeight': ['N/A'],
      'wavePeriod': ['N/A'],
      'windSpeed': ['N/A'],
      'windDirection': ['N/A'],
      'gustSpeed': ['N/A'],
      'airTemp': ['N/A'],
      'waterTemp': ['N/A'],
      'barometer': ['N/A'],
      'updateTime': ['N/A'],
    },
    southHectateStrait: {
      'name': "South Hectate Strait",
      'url': '/weatherDataSouthHectateStrait',
      'coordinates': {lat: 52.85, lng: -130.08},
      'waveHeight': ['N/A'],
      'wavePeriod': ['N/A'],
      'windSpeed': ['N/A'],
      'windDirection': ['N/A'],
      'gustSpeed': ['N/A'],
      'airTemp': ['N/A'],
      'waterTemp': ['N/A'],
      'barometer': ['N/A'],
      'updateTime': ['N/A'],
    },
    southMoresby: {
      'name': "South Moresby",
      'url': '/weatherDataSouthMoresby',
      'coordinates': {lat: 51.83, lng: -131.23},
      'waveHeight': ['N/A'],
      'wavePeriod': ['N/A'],
      'windSpeed': ['N/A'],
      'windDirection': ['N/A'],
      'gustSpeed': ['N/A'],
      'airTemp': ['N/A'],
      'waterTemp': ['N/A'],
      'barometer': ['N/A'],
      'updateTime': ['N/A'],
    },
    usaStation7: {
      'name': "USA Station 7",
      'url': '/landStationUsa7',
      'coordinates': { lat: 48.173, lng: -123.607 },
      'waveHeight': ['N/A'],
      'wavePeriod': ['N/A'],
      'windSpeed': ['N/A'],
      'windDirection': ['N/A'],
      'gustSpeed': ['N/A'],
      'airTemp': ['N/A'],
      'waterTemp': ['N/A'],
      'barometer': ['N/A'],
      'updateTime': ['N/A'],
    },
    // straitOfJDF: {
    //   'name': straitOfJDF,
    //   'coordinates': {lat: 48.493, lng: -124.727},
    //   'waveHeight': ['N/A'],
    //   'wavePeriod': ['N/A'],
    //   'windSpeed': ['N/A'],
    //   'windDirection': ['N/A'],
    //   'gustSpeed': ['N/A'],
    //   'airTemp': ['N/A'],
    //   'waterTemp': ['N/A'],
    //   'barometer': ['N/A'],
    //   'updateTime': ['N/A'],
    // }
};
const [weatherStation, setWeatherStation] = useState(initialWeatherStationState);
const [waveBuoy, setWaveBuoy] = useState(initialWaveBuoyState);
const [originalWeatherData, setOriginalWeatherData] = useState(null)
const [weatherData, setWeatherData] = useState(null);
const [fullArray, setFullArray] = useState(null);
const [countryName, setCountryName] = useState('');
const [userLocationAccepted, setUserLocationAccepted] = useState(false);
const [s3Files, setS3Files] = useState([]);


setTimeout(() => {
  

  setUserLocationAccepted(true)
}, 2000);

useEffect(() => {
  getGeoInfo(); // Call the function when the component mounts and updates with user country

  if (countryName === 'Canada' || countryName === 'United States') {
    setUserLocationAccepted(true);
  }
}, [countryName]);

const getGeoInfo = () => {
  axios.get('https://ipapi.co/json/')
      .then((response) => {
          const data = response.data;
          setCountryName(data.country_name);
      })
      .catch((error) => {
          console.log(error);
      });
};

  // meters to feet then * 2 as buoys only measure from water level to peak (not trough to peak)
  const waveHeightToFeet = (waveHeight) => {
    const height = ((waveHeight * 3.28084)*2).toFixed(1);
    return height;
  };

  // get user location every 60 seconds
  const getUserLocation = () => {
    if (navigator.geolocation) {
      const options  = {
        enableHighAccuracy: true,
        timeout: 60000,
        maximumAge: 0
      };
      const watchId = navigator.geolocation.watchPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.log('Error getting user location:', error);
        },
        options
      );      
      // navigator.geolocation.clearWatch(watchId);
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };

  const fetchAllData = async () => {
    try {
      await Promise.all([
        ...Object.keys(weatherStation).map(stationName => fetchDataForStation(stationName)),
        ...Object.keys(waveBuoy).map(buoyName => fetchDataForBuoy(buoyName))
      ]);
      setLoading(false);
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  const fetchLastTilesetTimestamp = async () => {
    try {
      const response = await fetch(API_BASE_URL + '/last-tileset-timestamp');
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      const timestampArray = [];
      for (let i = 1; i < 49; i++) {
        // always have 3 digits.. 001, 002, 012 etc
        timestampArray.push(data.timestamp + '_filtered_weather_data_' + (i < 10 ? '00' + i : '0' + i));
      }
      setS3Files(timestampArray);
    } catch (error) {
      console.error("Error fetching last tileset timestamp:", error);
      return null;
    }
  };
  

  const fetchDataForStation = async (stationName) => {
    try {
      const response = await axios.get(API_BASE_URL + weatherStation[stationName].url);
      const results = response.data.rows;
      setWeatherStation(prevData => ({
        ...prevData,
        [stationName]: {
          ...prevData[stationName],
          windSpeed: results.map(result => result.wind_speed === 'N/A' || result.wind_direction === 'calm' || parseFloat(result.wind_speed) < 2 ? 'calm' : parseFloat(result.wind_speed)),
          windDirection: results.map(result => result.wind_direction === 'N/A' ? 'Unknown' : result.wind_direction),
          updateTime: results.map(result => result.created_at || 'N/A')
        }
      }));
    } catch (error) {
      console.log(`Error fetching data for ${stationName}:`, error);
    }
  };

  const fetchDataForBuoy = async (buoyName) => {
    try {
      const response = await axios.get(API_BASE_URL + waveBuoy[buoyName].url);
      const results = response.data.rows;
      setWaveBuoy(prevData => ({
        ...prevData,
        [buoyName]: {
          ...prevData[buoyName],
          waveHeight: results.map((result) => waveHeightToFeet(result.waveheight) || 'N/A'),
          wavePeriod: results.map(result => result.waveperiod || 'N/A'),
          windSpeed: results.map(result => result.speed || 'N/A'),
          windDirection: results.map(result => result.direction || 'N/A'),
          gustSpeed: results.map(result => result.gustspeed || 'N/A'),
          airTemp: results.map(result => result.airtemp || 'N/A'),
          waterTemp: results.map(result => result.watertemp || 'N/A'),
          barometer: results.map(result => result.barometer || 'N/A'),
          updateTime: results.map(result => result.created_at || 'N/A')
        }
      }));
    } catch (error) {
      console.log(`Error fetching data for ${buoyName}:`, error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("userlocationaccepted", userLocationAccepted);
        await fetchAllData(); // Fetch weather & buoy data
        await fetchLastTilesetTimestamp(); // Fetch tileset info
        getUserLocation();
        setLoading(false); // ✅ Only set `loading` to false after all data is loaded
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Ensure UI doesn't stay stuck on loading
      }
    };
  
    if (userLocationAccepted) {
      fetchData();
    }
  }, [userLocationAccepted]);

  return (
    loading ? (
      <div className="wave-loader-container">
        <ElectricWave />
        <h3>Loading your experience...</h3>
      </div>
    ) : (
      <Context.Provider value={{ userLocation, weatherStation, waveBuoy, originalWeatherData, weatherData, fullArray, s3Files }}>
        {weatherStation && waveBuoy && userLocationAccepted ? (
          <Routes>
            <Route path="/" element={<WeatherMapBoxTileSet />} />
            <Route path="/fishingMap" element={<FishingMap />} />
            <Route path="/destinationsMap" element={<DestinationsMap />} />
            <Route path="/weatherTable" element={<WeatherTable />} />
            <Route path="/weatherForecast" element={<WeatherForecast />} />
            <Route path="/tides" element={<Tides />} />
            <Route path="/areaStatus" element={<AreaStatus />} />
            <Route path="/fishingAreaMap" element={<FishingAreaMap />} />
            <Route path="/FishingReportJune10" element={<FishingReportJune10 />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/termsAndConditions" element={<TermsAndConditions />} />
            <Route path="/helpPage" element={<HelpPage />} />
          </Routes>
        ) : (
          <div>
            <h3>Sorry, this product is not available...</h3>
          </div>
        )}
      </Context.Provider>
    )
  );
  
}

export default App;
