import React, { useState, useRef, useContext, useEffect } from 'react';
import { GoogleMap, Marker, InfoWindowF, InfoWindow, LoadScript, Polyline, Polygon } from '@react-google-maps/api';
import axios from 'axios';
import SidebarPro from '../global/sidebar';
import BottomNav from '../global/bottomNav';
import { fishingHotSpotsArray } from '../../fishingData/fishingHotSpots';
import { fishingActiveHotSpots } from '../../fishingData/fishingActiveHotSpots';
import { regulationUpdates } from '../../fishingData/regulationUpdates';
import ModalLarge from '../global/modalLarge';
import MapRegsSpeciesSelection from '../fishingMap/mapRegsSpeciesSelection';
import { NavLink, useNavigate } from 'react-router-dom';
import '../../App.css';
import FishingLayerCheckbox from '../fishingMap/fishingLayerCheckbox';
import { Context } from '../context/context';
import Footer from '../global/footer';
import SimpleCloseBtn from '../global/simpleCloseBtn';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { getSvgUrl } from '../../utils/utils';
import DropDownMsg from '../global/dropDownMsg';
import 'animate.css';

function FishingMap() {

  const [fishingAreaDataArray, setFishingAreaData] = useState([]);
  const [closureAreas, setClosureAreas] = useState([]);

  useEffect(() => {
    import('../../fishingData/fishingAreas')
        .then((module) => setFishingAreaData(module.fishingAreaDataArray))
        .catch((err) => console.error("Error loading fishing area data:", err));

    import('../../fishingData/closureAreas')
        .then((module) => setClosureAreas(module.closureAreas))
        .catch((err) => console.error("Error loading fishing closure data:", err));
}, []);

  const navigate = useNavigate();

  // const westCoastVanIslaOpeningCoords = [
  //   { lat: 48.722117, lng: -125.097583 }, // Pacheena Point Lighthouse
  //   { lat: 48.707600, lng: -125.109717 }, // Seaward of Pacheena Point
  //   { lat: 48.773667, lng: -125.233283 }, // Seaward of Cape Beale
  //   { lat: 48.909533, lng: -125.560367 }, // Seaward of Amphitrite Point
  //   { lat: 49.085000, lng: -125.910767 }, // Seaward of Cox Point
  //   { lat: 49.171333, lng: -126.079833 }, // Seaward of Blunden Island
  //   { lat: 49.274533, lng: -126.252333 }, // Seaward of Rafael Point
  //   { lat: 49.333467, lng: -126.288133 }, // Seaward of Sydney Inlet
  //   { lat: 49.396783, lng: -126.408050 }, // Seaward of Hesquiat Point
  //   { lat: 49.360333, lng: -126.474633 }, // Seaward of Matlahaw Point
  //   { lat: 49.368550, lng: -126.559467 }, // Seaward of Estevan Point
  //   { lat: 49.397817, lng: -126.592217 }, // Seaward of Homais Cove
  //   { lat: 49.462767, lng: -126.599517 }, // Seaward of Split Cape
  //   { lat: 49.524900, lng: -126.594483 }, // Seaward of Escalante Point
  //   { lat: 49.567367, lng: -126.693517 }, // Seaward of Maquinna Point
  //   { lat: 49.604233, lng: -126.842300 }, // Seaward of Bajo Point
  //   { lat: 49.664867, lng: -126.918750 }, // Seaward of Skuna Bay
  //   { lat: 49.740000, lng: -127.004817 }, // Seaward of Ferrer Point
  //   { lat: 49.846117, lng: -127.169183 }, // Seaward of Tatchu Point
  //   { lat: 49.985700, lng: -127.468750 }, // Seaward of Lookout Island
  //   { lat: 50.115800, lng: -127.693617 }, // Seaward of Jackobson Point
  //   { lat: 50.059983, lng: -127.795367 }, // Seaward of Clerke Point
  //   { lat: 50.098100, lng: -127.965100 }, // Seaward of Solander Island
  //   { lat: 50.321400, lng: -128.002167 }, // Seaward of Lawn Point
  //   { lat: 50.525017, lng: -128.237300 }, // Seaward of Topknot Point
  //   { lat: 50.594717, lng: -128.320817 }, // Seaward of Cape Palmerston
  //   { lat: 50.654667, lng: -128.391983 }, // Seaward of Winifred Island
  //   { lat: 50.685267, lng: -128.403767 }, // Seaward of Cape Russell
  //   { lat: 50.735617, lng: -128.442650 }, // Seaward of Strange Rock
  //   { lat: 50.791767, lng: -128.456050 }, // Seaward of Cape Scott
  //   // Frederiksen Point - No coordinates provided
  // ];
  
    const userLocation = useContext(Context).userLocation;

    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const mapId = '153fb55674dee1df';
    const libraries = ['places'];
    const mapRef = useRef(null); // Reference to Google Map component
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const hotSpot = require('../../images/fishing.png');
    const iconUrl = getSvgUrl();

    const [zoomLevel, setZoomLevel] = useState(10);
    const [render, setRender] = useState(false);
    const [clickedFishingArea, setClickedFishingArea] = useState(null);
    const [hoveredFishingArea, setHoveredFishingArea] = useState(null);
    const [mapCenter, setMapCenter] = useState({ lat: 49.2827, lng: -123.3207 });
    const [mobile, setMobile] = useState(window.innerWidth < 600);
    const [showBanner, setShowBanner] = useState(false);
    const [delayRender, setDelayRender] = useState(false);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [fishingRegulationUpdates, setFishingRegulationUpdates] = useState(null);
    const [fishingCheckboxStatus, setFishingCheckboxStatus] = useState({
      fishingAreas: 'on',
      RCA: 'on',
      spongeReef: 'on',
      hotSpots: 'on',
      activeHotSpots: 'on'
    });
    const [sidePanel, setSidePanel] = useState({
      isPaneOpen: false,
      isPaneOpenLeft: false,
    });

    useEffect(() => {
      setTimeout(() => {
        setDelayRender(true)
      }, 2000);
    }, []);

    const handleFishingReportClick = () => {
      // Redirect to the '/fishingReport' page
      navigate('/FishingReportJune10');
    };

    // get all fishing regulation updates/notices
    useEffect(() => {
      axios.get(`${API_BASE_URL}/fishingRegulationUpdates`)
        .then(response => {
          setFishingRegulationUpdates(response.data.rows);
          console.log('regulation updates', response.data.rows);
          // setRegulationUpdates(response.data);
        })
        .catch(error => {
          console.log('error', error);
        });
    }, []);

    setTimeout(() => {
        setRender(true);
    }, 500);

    const handleHoveredMarker = () => {
      setTimeout(() => {
        setSelectedMarker(null);
      }, 750);
    }

    const returnToMap = () => {
        setClickedFishingArea(null);
    }

    const handleSettingsCheckBoxChange = (event) => {
      // localStorage.setItem('userSettings', JSON.stringify(response.data[0]));
      const { name, checked } = event.target;
      setFishingCheckboxStatus((prevSettings) => ({
        ...prevSettings,
        [name]: checked ? 'on' : 'off'
      }));
    };

    useEffect(() => {
      const bannerMsg = localStorage.getItem("showedBanner");
      if(bannerMsg === "true") {
        setShowBanner(false);
      } else {
        // Delay setting the state to show the banner
        const timeoutId = setTimeout(() => {
          setShowBanner(true);
        }, 1500); // Adjust the delay time as needed
    
        // Cleanup function to clear the timeout if the component unmounts
        return () => clearTimeout(timeoutId);
      }
    }, []);
    
  return (
    <div className="fishing-map-page">
      <button className='animate__bounceIn weather-slide-btn' onClick={() => setSidePanel({ isPaneOpen: true })}>
        Regulation Updates
      </button>
      {/* {delayRender && <DropDownMsg onClick={handleFishingReportClick} className='animate__bounceIn' msg='June 10th Fishing Report' />} */}
      <SlidingPane
        overlayClassName="weather-slider"
        isOpen={sidePanel.isPaneOpen}
        title="Fishing Regulations Notice Summary"
        // subtitle="Optional subtitle."
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setSidePanel({ isPaneOpen: false });
        }}
      >
    {fishingRegulationUpdates &&
      fishingRegulationUpdates
        .slice() // Create a shallow copy of the array to avoid mutating the original data
        .sort((a, b) => {
          const noticeNumberA = parseInt(a.notice_number.slice(2), 10); // Extract and convert notice number to integer
          const noticeNumberB = parseInt(b.notice_number.slice(2), 10);
          return noticeNumberB - noticeNumberA; // Sort in descending order
        })
        .map((regulation, index) => (
          <div key={index} className='side-panel-container'>
            <div>
              {/* <div className='side-panel-inline'>
                <h5>{regulation.notice_number}</h5>
              </div> */}
              <h3>{regulation.title}</h3>
              <a href={regulation.link} target='_blank' rel='noreferrer'>More Details</a>
            </div>
          </div>
        ))
    }
      </SlidingPane>
    {showBanner && 
      <span className='new-features-banner'>
        <SimpleCloseBtn onClick={() => {
          localStorage.setItem("showedBanner", "true")
          setShowBanner(false)
        }} />
        <h2>New Features!</h2>
        <p>Check out our new regulations, showing partial openings and specific closures within a sub area.</p>
        <img src={require('../../images/partialOpening.png')} alt='logo' className='new-feature-img' />
      </span>}
      {mobile ? <BottomNav /> : <SidebarPro />}
      <LoadScript 
        googleMapsApiKey={apiKey} 
        libraries={libraries} 
        mapIds={[mapId]}
        >
      <FishingLayerCheckbox fishingCheckboxStatus={fishingCheckboxStatus} handleSettingsCheckBoxChange={handleSettingsCheckBoxChange} />
      {clickedFishingArea && 
      <ModalLarge onClick={returnToMap}>
        <MapRegsSpeciesSelection returnToMap={returnToMap} clickedFishingArea={clickedFishingArea} />
      </ModalLarge>
      }
      {hoveredFishingArea?.length > 1 && <div className='hovered-fishing-area'>Area: {hoveredFishingArea}</div>}
      <GoogleMap
        ref={mapRef}
        mapContainerStyle={{ height: '100vh', width: '100vw' }}
        center={mapCenter} // Use user's location if available, otherwise use default center
        zoom={zoomLevel}
        options={
          {
            fullscreenControl: false,
            mapTypeControl: false, 
            mapId: mapId 
          }
          }
        >
          {render && userLocation.lat !== 0 && userLocation.lng !== 0 && window.google && window.google.maps &&
            <Marker
              position={{ lat: userLocation.lat, lng: userLocation.lng }}
              icon={{
                url: require('../../images/userDot.png'),
                scaledSize: new window.google.maps.Size(15, 15)
              }}
            />          
          }
          {render && fishingCheckboxStatus.fishingAreas === 'on' && fishingAreaDataArray && !clickedFishingArea &&
            fishingAreaDataArray.map((fishingArea, index) => {
                return (
                    <Polygon
                        key={index}
                        path={fishingArea.coordinates}
                        options={{
                            fillColor: 'rgba(0,0,0,1)',
                            fillOpacity: 
                            (fishingArea.properties.LABEL.length > 1 && hoveredFishingArea) ? 
                              (fishingArea.properties.LABEL === hoveredFishingArea ? 0.1 : 0.01) :
                              0.01,
                            strokeColor: 'rgba(255,255,255,0.65)',
                            strokeOpacity: 1,
                            strokeWeight: .5
                        }}
                        onMouseOver={() => {
                            setHoveredFishingArea(fishingArea.properties.LABEL);
                        }
                        }
                        onMouseOut={() => {
                            setHoveredFishingArea(null);
                        }
                        }
                        onClick={() => {
                            setClickedFishingArea(fishingArea.properties.LABEL); 
                        }
                        }
                    />
                );
            }
            )}
            {render && fishingCheckboxStatus.RCA === 'on' && closureAreas &&
              closureAreas.map((area, index) => {
              if(area.type === 'rockfish closure') {
              return (
              <Polygon
                key={index}
                path={area.coordinates}
                options={{
                    fillColor: 'rgba(255,100,100,.5)',
                    fillOpacity: .15,
                    strokeColor: 'red',
                    strokeOpacity: .5,
                    strokeWeight: .5,
                  }}
              />
                )}
              })
            }
            {render && fishingCheckboxStatus.spongeReef === 'on' && closureAreas &&
              closureAreas.map((area, index) => {
              if(area.type === 'sponge reef closure') {
              return (
              <Polygon
                key={index}
                path={area.coordinates}
                options={{
                    fillColor: 'yellow',
                    fillOpacity: .2,
                    strokeColor: 'yellow',
                    strokeOpacity: 1,
                    strokeWeight: .5,
                  }}
              />
                )}
              })
            }
            {render && fishingHotSpotsArray && fishingCheckboxStatus.hotSpots === 'on' && window.google && window.google.maps &&
            fishingHotSpotsArray.map((fishingHotSpot, index) => {
              return (
                <Marker
                  key={index}
                  position={{ lat: fishingHotSpot.coordinates.lat, lng: fishingHotSpot.coordinates.lng }}               
                  icon={{
                    url: hotSpot,
                    scaledSize: new window.google.maps.Size(15, 12)
                  }}
                  onMouseOver={() => setSelectedMarker(fishingHotSpot)}
                  onMouseOut={handleHoveredMarker}
                />
              );
            })
          }
          {selectedMarker && (
            <InfoWindow
              position={{
                lat: selectedMarker.coordinates.lat,
                lng: selectedMarker.coordinates.lng
              }}
              onCloseClick={() => setSelectedMarker(null)}
              disableAutoPan={true}
              disablePanOnClick={true}
              zIndex={999}
              >
              <div>
                <h3>{selectedMarker.name}</h3>
                {selectedMarker.species && <h3 style={{margin: 0, padding: 0, fontWeight: 200}}>{selectedMarker.species} are hot right now!</h3>}
              </div>
            </InfoWindow>
          )}
          {fishingActiveHotSpots && fishingCheckboxStatus.activeHotSpots === 'on' && window.google && window.google.maps &&
          fishingActiveHotSpots.map((fishingHotSpot, index) => {
              return (
                <Marker
                  key={index}
                  position={fishingHotSpot.coordinates}
                  icon={{
                    url: iconUrl,
                    anchor: new window.google.maps.Point(10, 12),  // Anchor point at the center of the icon
                    scaledSize: new window.google.maps.Size(55, 55)
                  }}
                  onMouseOver={() => setSelectedMarker(fishingHotSpot)}
                  onMouseOut={handleHoveredMarker}
                />
              );
            }
          )
          }
        </GoogleMap>
      </LoadScript>
    </div>
  );
}

export default FishingMap;
