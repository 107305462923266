import { motion } from "framer-motion";

const ElectricWave = () => {
  return (
    <svg viewBox="0 0 500 200" fill="none" xmlns="http://www.w3.org/2000/svg" className="electric-wave">
      <defs>
        {/* Moving Gradient */}
        <linearGradient id="movingGradient" x1="0%" x2="100%">
          <stop offset="0%" stopColor="cyan" />
          <stop offset="50%" stopColor="blue" />
          <stop offset="100%" stopColor="cyan" />
        </linearGradient>
      </defs>

      {/* Static black wave outline */}
      <motion.path
        d="M10 150 Q 150 50, 250 120 T 490 120"
        stroke="black"
        strokeWidth="3"
        fill="transparent"
      />

      {/* Moving blue wave effect */}
      <motion.path
        d="M10 150 Q 150 50, 250 120 T 490 120"
        stroke="url(#movingGradient)"
        strokeWidth="5"
        fill="transparent"
        strokeLinecap="round"
        initial={{ pathLength: 0, opacity: 1 }}
        animate={{
          pathLength: [0, 1, 1, 0], // Moves from left to right
          opacity: [1, 1, 1, 0], // Gradually fades out at the right
        }}
        transition={{
          duration: 3, // Controls speed of movement
          repeat: Infinity,
          ease: "linear",
        }}
      />
    </svg>
  );
};

export default ElectricWave;
