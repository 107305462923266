import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { transformKey } from '../../functions/transformKey';
import { IoIosArrowBack } from "react-icons/io";
import fishSpeciesArray from '../../fishingData/fishSpecies';

const AreaSearchSpeciesSelection = ({ setSelectedSpeciesName }) => {

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [uniqueSpecies, setUniqueSpecies] = useState([]);
    const [selectedSpecies, setSelectedSpecies] = useState('');
    const [fishSpeciesArray, setFishSpeciesArray] = useState([]);

    useEffect(() => {
        import('../../fishingData/fishSpecies')
            .then((module) => setFishSpeciesArray(module.fishSpeciesArray))
            .catch((err) => console.error("Error loading fishing area data:", err));

    }, []);

    const uniqueSpeciesTypes = Array.from(new Set(fishSpeciesArray.map(item => item.species_type)));

    useEffect(() => {
        const speciesNames = fishSpeciesArray
        .filter(item => item.species_type === selectedSpecies)
        .map(item => item.species_name);

        // Get unique species names using Set
        const uniqueSpeciesNames = Array.from(new Set(speciesNames));

        setUniqueSpecies(uniqueSpeciesNames);
    }, [selectedSpecies]);

    let returnText = '';
    if(selectedSpecies) {
        returnText = 'Species';
    } else {
        returnText = '';
    }
    
    return (
        <>
        {selectedSpecies &&
            <p
                className='modal-return-to-btn'
                onClick={() => {
                    setSelectedSpecies('');
                }}
            >
                <IoIosArrowBack size={11} />
                Return To {returnText}
            </p>
        }
            <h3 className='species-area-search-header'>Select a species to view it's status on the map</h3>
            <div className={uniqueSpeciesTypes && selectedSpecies === '' ? 'fishing-regs-tile-container-small' : 'fishing-regs-tile-container'}>
                {uniqueSpeciesTypes && selectedSpecies === '' && (
                    uniqueSpeciesTypes
                    .sort()
                    .map((speciesType, index) => (
                        <div 
                            className='fishing-regs-tile' 
                            key={index}
                            onClick={() => {
                                setSelectedSpecies(speciesType);
                            }
                            }
                        >
                            <p className='tile-text'>{speciesType}</p>
                        </div>
                    )))            
                }
                {uniqueSpecies.length > 0 &&
                    uniqueSpecies
                        .map((item, index) => (
                            <div
                                className='fishing-regs-tile' 
                                key={index}
                                onClick={() => {
                                    setSelectedSpeciesName(item);
                                }
                                }
                            >
                                <p style={item?.length > 26 ? {fontSize: '14px'} : {}} className='tile-text'>{transformKey(item)}</p>
                            </div>    
                        ))
                }
            </div>
        </>

    );
    }

    export default AreaSearchSpeciesSelection;