import React, { useState, useEffect } from "react";

const PopupSelector = ({ forecastType, changeForecastModel }) => {

  const [flash, setFlash] = useState(false);

  useEffect(() => {
    // Trigger flash animation
    setFlash(true);
    const timer = setTimeout(() => setFlash(false), 2500); // Flash for 3s
    return () => clearTimeout(timer);
}, []);

  return (
    <div className="popup-toggle">
      <div className="popup">
        <div
          className={`option ${forecastType === "wind" ? "selected" : ""}`}
          onClick={() => {
            changeForecastModel("wind")
          }}
        >
          Wind
        </div>
        <div
          className={`option ${flash ? "pulse-animation" : ""} ${forecastType === "wave" ? "selected" : ""}`}
          onClick={() => {
            changeForecastModel("wave")
          }}
        >
          Wave
        </div>
      </div>
    </div>
  );
};

export default PopupSelector;
