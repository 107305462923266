import React, { useState, useRef, useEffect, useContext } from 'react';
import { GoogleMap, Marker, InfoWindowF, InfoWindow, LoadScript, Polyline, Polygon } from '@react-google-maps/api';
import SidebarPro from '../global/sidebar';
import BottomNav from '../global/bottomNav';
import WeatherSettingsBtn from '../weatherMap/weatherSettingsBtn';
import '../../App.css';
import WeatherLayerCheckBox from '../weatherMap/weatherLayerCheckbox';
import { transformTime } from '../../functions/transformTime';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { convertUnitsUserSettings } from '../../functions/convertUnits';
import WeatherMapUnits from '../weatherMap/weatherMapUnits';
import { mainBlue, mainBlueFifty } from '../../styling/colors';
import { GiBigWave } from "react-icons/gi";
import { FiWind } from "react-icons/fi";
import { Context } from '../context/context';
import Footer from '../global/footer';
import { NavLink, useNavigate } from 'react-router-dom';
import * as geolib from 'geolib';
import axios from 'axios';
import { ref, uploadBytes, getDownloadURL, updateMetadata, listAll, getMetadata } from 'firebase/storage';
import { storage } from '../global/firebaseConfig';
import PhotoPreview from '../weatherMap/photoPreview';
import { newWebCamArray } from '../../weatherData/newWebcams';
import SimpleCloseBtn from '../global/simpleCloseBtn';
import WindDirectionIndicatorLarge from '../global/windArrowLarge';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { getSvgUrl } from '../../utils/utils';

ChartJS.register(...registerables);

function WeatherMap() {
  

  const [BCPolygon, setBCPolygon] = useState([]);

  useEffect(() => {
    import('../../fishingData/BCpolygon')
        .then((module) => setBCPolygon(module.BCPolygon))
        .catch((err) => console.error("Error loading BC polygon data:", err));
}, []);

  // const polygonString = "";

  // function parsePolygonCoordinates(polygonString) {
  //   const coordinates = polygonString
  //       .replace('POLYGON ((', '')
  //       .replace('))', '')
  //       .split(', ')
  //       .map(coord => {
  //           const [lng, lat] = coord.split(' ').map(parseFloat);
  //           return { lat, lng };
  //       });

  //   console.log(coordinates); // Log the output here
  // }

  // parsePolygonCoordinates(polygonString);

    const navigate = useNavigate();

    const userLocation = useContext(Context).userLocation
    const weatherStation = useContext(Context).weatherStation
    const waveBuoy = useContext(Context).waveBuoy
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const mapId = '153fb55674dee1df';
    const libraries = ['places'];
    const mapRef = useRef(null); // Reference to Google Map component
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const [zoomLevel, setZoomLevel] = useState(10);
    const [mobile, setMobile] = useState(window.innerWidth < 600);
    const [render, setRender] = useState(false);
    const [showSettingsDropdown, setShowSettingsDropdown] = useState(false);
    const [mapCenter, setMapCenter] = useState({ lat: 49.2827, lng: -123.3207 });
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [hoveredMarker, setHoveredMarker] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [showBanner, setShowBanner] = useState(false);
    const [photoArray, setPhotoArray] = useState([]);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [selectedWebcam, setSelectedWebcam] = useState(null);
    const [weatherCheckboxStatus, setWeatherCheckboxStatus] = useState({
      waveHeight: 'on',
      windSpeed: 'on',
      webCams: 'on',
      photos: 'on',
    });
    const [weatherUnits, setWeatherUnits] = useState({
      waveHeight: 'feet',
      windSpeed: 'miles',
    });
    const [sidePanel, setSidePanel] = useState({
      isPaneOpen: false,
      isPaneOpenLeft: false,
    });
    const iconUrl = getSvgUrl('weather');

    setTimeout(() => {
      setRender(true);
  }, 500);

  const handleFileChange = (event) => {
    // Check if user is within polygon
    if (userLocation.lat > 0) {
      const isInPolygon = geolib.isPointInPolygon({ latitude: userLocation.lat, longitude: userLocation.lng }, BCPolygon);
      // Check if the user is not inside the polygon and alert the user
      if (!isInPolygon) {
        alert('You must be on the BC coast to upload a photo');
        return;
      }
    }
  
    const file = event.target.files[0];
    setSelectedFile(file);
  
    // Show preview of the selected image
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };  

  useEffect(() => {
    console.log(zoomLevel)

  }, [zoomLevel]);

  const handleSubmit = () => {
    // Handle the file upload
    if (selectedFile) {
      setShowLoader(true);
      const imgRef = ref(storage, 'uploads/' + selectedFile.name);
  
      // Upload the file
      uploadBytes(imgRef, selectedFile)
        .then((snapshot) => {
          console.log('File uploaded successfully:', snapshot);
  
          // Get the download URL of the uploaded file
          return getDownloadURL(imgRef);
        })
        .then((downloadURL) => {
          // Update metadata with custom coordinates
          const metadata = {
            contentType: 'image/jpeg', // Set the content type of the file
            customMetadata: {
              latitude: userLocation.lat,
              longitude: userLocation.lng,
            },
          };
  
          // Update metadata of the uploaded file
          return updateMetadata(imgRef, metadata);
        })
        .then(() => {
          console.log('Metadata updated successfully');
          // Reset the selected file and preview image
          setSelectedFile(null);
          setPreviewImage(null);
          setShowLoader(false);
        })
        .catch((error) => {
          console.error('Error uploading file:', error);
        });
    } else {
      console.log('No file selected');
    }
  };

  const retrievePhotosWithinTimeRange = async () => {
    const currentTime = new Date();
    const sixHoursAgo = new Date(currentTime.getTime() - 6 * 60 * 60 * 1000); // 6 hours ago
  
    try {
      // Retrieve a list of all files in the Firebase Storage folder
      const files = await listAll(ref(storage, 'uploads'));
  
      // Filter files based on their upload date and time
      const filteredFiles = await Promise.all(files.items.map(async (fileRef) => {
        const metadata = await getMetadata(fileRef);
        const uploadTime = new Date(metadata.timeCreated);
  
        // Check if upload time is within 6 hours of current time
        if (uploadTime >= sixHoursAgo && uploadTime <= currentTime) {
          return {
            name: fileRef.name,
            downloadURL: await getDownloadURL(fileRef),
            metadata: metadata,
          };
        } else {
          return null;
        }
      }));
  
      // Remove null entries and return the filtered files
      return filteredFiles.filter((file) => file !== null);
    } catch (error) {
      console.error('Error retrieving photos:', error);
      return [];
    }
  };

  useEffect(() => {

  retrievePhotosWithinTimeRange()
  .then((photos) => {
    setPhotoArray(photos);
  })
  .catch((error) => {
    console.error('Error:', error);
  });

  }, []);
  
  const handleWeatherClick = (value) => {  
    // Redirect to the '/weatherTable' page and pass the weatherData as state
    navigate('/weatherTable', { state: value});
  };

    const handleUnitChange = (event) => {
      const { name, value } = event.target;
      setWeatherUnits((prevUnits) => ({
          ...prevUnits,
          [name]: value,
      }));
    };

    const onLoad = (map) => {
        map.addListener('zoom_changed', () => {
          setZoomLevel(map.zoom)
        });
    }

    const setWeatherSettingsOpen = () => {
      setShowSettingsDropdown(!showSettingsDropdown)
    }

    useEffect(() => {
      const bannerMsg = localStorage.getItem("showedPhotoBanner");
      if(bannerMsg === "true") {
        setShowBanner(false);
      } else {
        // Delay setting the state to show the banner
        const timeoutId = setTimeout(() => {
          setShowBanner(true);
        }, 1500); // Adjust the delay time as needed
    
        // Cleanup function to clear the timeout if the component unmounts
        return () => clearTimeout(timeoutId);
      }
    }, []);

    const analyzeWaveHeights = (waveHeights) => {

      const len = waveHeights.length;
      if (len < 2) return "Not enough data to analyze wave heights.";

      const latest = waveHeights[0];
      const secondLatest = waveHeights[1];
      const thirdLatest = waveHeights[2];
        
      if (len >= 3 && latest > secondLatest && secondLatest > thirdLatest) {
        return "Waves have been consistently building over the last 3 hours.";
      }
      if (len >= 2 && latest > secondLatest && secondLatest > thirdLatest) {
        return "Waves have been building over the past 2 hours.";
      }
      if (latest > secondLatest) {
        return "Waves are starting to increase.";
      }
      if (len >= 2 && Math.abs(latest - secondLatest) / secondLatest <= 0.05) {
        return "Waves have remained consistent.";
      }
      if (len >= 3 && latest < secondLatest && secondLatest < thirdLatest) {
        return "Waves have been consistently decreasing over the last 3 hours.";
      }
      if (len >= 2 && latest < secondLatest && secondLatest < thirdLatest) {
        return "Waves have been decreasing over the past 2 hours.";
      }
      if (latest < secondLatest) {
        return "Waves are starting to decrease.";
      }
    
      return "Wave heights are fluctuating.";
    };

    const analyzeWindSpeeds = (windSpeeds) => {
      const len = windSpeeds.length;
      if (len < 2) return "Not enough data to analyze wind speed.";
    
      const latest = parseFloat(windSpeeds[0]);
      const secondLatest = parseFloat(windSpeeds[1]);
      const thirdLatest = parseFloat(windSpeeds[2]);
    
      if (len >= 3 && latest > secondLatest && secondLatest > thirdLatest) {
        return "The wind has been consistently building over the last 3 hours.";
      }
      if (len >= 2 && latest > secondLatest && secondLatest > thirdLatest) {
        return "The wind has been building over the past 2 hours.";
      }
      if (latest > secondLatest) {
        return "The wind is starting to increase.";
      }
      if (len >= 2 && Math.abs(latest - secondLatest) / secondLatest <= 0.05) {
        return "The wind has remained consistent.";
      }
      if (len >= 3 && latest < secondLatest && secondLatest < thirdLatest) {
        return "The wind has been consistently decreasing over the last 3 hours.";
      }
      if (len >= 2 && latest < secondLatest && secondLatest < thirdLatest) {
        return "The wind has been decreasing over the past 2 hours.";
      }
      if (latest < secondLatest) {
        return "The wind is starting to decrease.";
      }
    
      return "The wind has been fluctuating.";
    };

    const categorizeDirection = (direction) => {
      switch (direction) {
        case 'N':
          return 'N';
        case 'NNE':
        case 'NE':
        case 'ENE':
          return 'NE';
        case 'E':
          return 'E';
        case 'ESE':
        case 'SE':
        case 'SSE':
          return 'SE';
        case 'S':
          return 'S';
        case 'SSW':
        case 'SW':
        case 'WSW':
          return 'SW';
        case 'W':
          return 'W';
        case 'WNW':
        case 'NW':
        case 'NNW':
          return 'NW';
        default:
          return 'Unknown';
      }
    };

    const analyzeWindDirections = (windDirections) => {
      const len = windDirections.length;
      if (len < 6) return "Not enough data available.";
    
      const lastSixDirections =[
        windDirections[0],
        windDirections[1],
        windDirections[2],
        windDirections[3],
        windDirections[4],
        windDirections[5],
      ]
      const categorizedDirections = lastSixDirections.map(categorizeDirection);
    
      const directionCounts = categorizedDirections.reduce((acc, dir) => {
        acc[dir] = (acc[dir] || 0) + 1;
        return acc;
      }, {});
    
      const predominantDirection = Object.keys(directionCounts).reduce((a, b) =>
        directionCounts[a] > directionCounts[b] ? a : b
      );
    
      return `Wind has typically been coming from the ${predominantDirection}.`;
    };

    const getRotationAngle = (windDirection) => {
      const windDirectionMap = {
        'N': require('../../images/windArrows/windN.png'),
        'NNE': require('../../images/windArrows/windNE.png'),
        'NE': require('../../images/windArrows/windNE.png'),
        'ENE': require('../../images/windArrows/windNE.png'),
        'E': require('../../images/windArrows/windE.png'),
        'ESE': require('../../images/windArrows/windSE.png'),
        'SE': require('../../images/windArrows/windSE.png'),
        'SSE': require('../../images/windArrows/windSE.png'),
        'S': require('../../images/windArrows/windS.png'),
        'SSW': require('../../images/windArrows/windSW.png'),
        'SW': require('../../images/windArrows/windSW.png'),
        'WSW': require('../../images/windArrows/windSW.png'),
        'W': require('../../images/windArrows/windW.png'),
        'WNW': require('../../images/windArrows/windNW.png'),
        'NW': require('../../images/windArrows/windNW.png'),
        'NNW': require('../../images/windArrows/windNW.png'),
      };

      // Default rotation angle for unknown wind directions
      const defaultAngle = 0;

      // Convert wind direction to uppercase for case-insensitivity
      if(windDirection === 'calm') return defaultAngle;
      if(windDirection === 'N/A') return defaultAngle;
      if(windDirection === 'Unknown') return defaultAngle;
      if(windDirection === '-') return defaultAngle;
      const normalizedWindDirection = windDirection;
    
      // Get the rotation angle from the map or use the default angle
      return windDirectionMap[normalizedWindDirection] || defaultAngle;
    };  


    const handleSettingsCheckBoxChange = (event) => {
      // localStorage.setItem('userSettings', JSON.stringify(response.data[0]));
      const { name, checked } = event.target;
      setWeatherCheckboxStatus((prevSettings) => ({
        ...prevSettings,
        [name]: checked ? 'on' : 'off'
      }));
    };

    const generateChartData = (selectedMarker) => {

      const updateTimes = selectedMarker.updateTime;

      const formattedTimeArray = []

      updateTimes.forEach((data) => {
        const date = new Date(data);
        const formattedTime = date.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'America/Los_Angeles',
        });

        formattedTimeArray.push(formattedTime);

      });

      // Reverse the array to display the most recent data first
      formattedTimeArray.reverse();


      // Reverse the order of the data array (if needed)
      const reversedData = selectedMarker.waveHeight
      ? [...selectedMarker.waveHeight].reverse()
      : [...selectedMarker.windSpeed].reverse();
    
        const data = {
          labels: formattedTimeArray,
          datasets: [
            {
              label: selectedMarker.waveHeight ? 'Wave Height' :'Wind Speed',
              data: reversedData,
              fill: true,
              tension: 0.4,
              backgroundColor: 'rgba(255,165,0,.6)',
              borderColor: mainBlueFifty,
            },
          ],
        };

        return data

    };

    const renderInfoWindow = () => {

        if (!selectedMarker) return null;
    
        let content;
        if (selectedMarker.type === 'weatherStation') {
          content = (
            <div className='info-window-container'>
              <div
                onClick={() => handleWeatherClick('wind')}
              >
                <FiWind className='weather-icon' size={25} color='black' />
              </div>
              <h3>{selectedMarker.name}</h3>
              <p>Wind Direction: {selectedMarker.windDirection[0]}</p>
              <p>Wind Speed: {convertUnitsUserSettings(selectedMarker.windSpeed[0], weatherUnits.windSpeed) + ` ` + weatherUnits.windSpeed}</p>
              <p>Update Time: {transformTime(selectedMarker.updateTime[0])}</p>
              <div className='line-chart-container'>
                <Line data={generateChartData(selectedMarker)} options={{
                  scales: {
                    y: {
                      beginAtZero: true,
                      suggestedMax: selectedMarker.windSpeed[0] + 3,
                    }, 
                    x: {
                      display: false,
                    }
                  },
                  plugins: {
                  legend: {
                    display: false
                    }
                  },
                }} />
              </div>
            </div>
          );
        } else {
            content = (
              <div className='info-window-container'>
                <div
                  onClick={() => handleWeatherClick('wave')}
                >
                  <GiBigWave className='weather-icon' size={25} color='black' />
                </div>
                <h3>{selectedMarker.name}</h3>
                <div className='map-info-window-data'>
                  <p>Wave Height: {convertUnitsUserSettings(selectedMarker.waveHeight[0], weatherUnits.waveHeight) + ` ` + weatherUnits.waveHeight}</p>                  
                  <p>Wind Speed: {convertUnitsUserSettings(selectedMarker.windSpeed[0], weatherUnits.windSpeed) + ` ` + weatherUnits.windSpeed}</p>
                  <p>Wind Direction: {selectedMarker.windDirection[0]}</p>
                  <p>Update Time: {transformTime(selectedMarker.updateTime[0])}</p>
                </div>
                <div className='line-chart-container'>
                  <Line data={generateChartData(selectedMarker)} options={{
                    scales: {
                      y: {
                        beginAtZero: true,
                        suggestedMax: selectedMarker.waveHeight[0]
                      },
                      x: {
                        display: false,
                      }
                    },
                    plugins: {
                    legend: {
                      display: false
                      }
                    }
                    }} />
                  </div>
                </div>
              );
            }
    
        return (
          <InfoWindow
            position={selectedMarker.coordinates}
            onCloseClick={() => {
              setSelectedMarker(null);
            }}
          >
            <div>{content}</div>
          </InfoWindow>
        );
      };

  return (
    <div className="weather-map-page">
      <button className='weather-slide-btn' onClick={() => setSidePanel({ isPaneOpen: true })}>
        Weather Summary
      </button>
      <SlidingPane
        overlayClassName="weather-slider"
        isOpen={sidePanel.isPaneOpen}
        title="Wave Buoy Weather Summary"
        // subtitle="Optional subtitle."
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setSidePanel({ isPaneOpen: false });
        }}
      >
      {waveBuoy && Object.keys(waveBuoy).map(buoyKey => {
        return(

          <div className="side-panel-container">
            <div>
              <h3 className='mobile-wrap'>{waveBuoy[buoyKey].name}</h3>
              <p>{analyzeWaveHeights(waveBuoy[buoyKey].waveHeight)}</p>
              <p>{analyzeWindSpeeds(waveBuoy[buoyKey].windSpeed)}</p>
              <p>{analyzeWindDirections(waveBuoy[buoyKey].windDirection)}</p>
            </div>
          </div>
        )

      })
      }
      </SlidingPane>
      {showBanner && 
      <span className='new-features-banner'>
        <SimpleCloseBtn onClick={() => {
          localStorage.setItem("showedPhotoBanner", "true")
          setShowBanner(false)
        }} />
        <h2>New Features!</h2>
        <p>Share real-time photos of conditions while you're out on the water. Help fellow boaters stay informed about current wave, weather, and overall conditions.</p>
        <p style={{fontSize: '12px'}}>*Only available on mobile. Photos expire after 6 hours.</p>
        <img src={require('../../images/uploadPhoto.png')} alt='logo' className='new-feature-photo-img' />
        <div className="arrow"></div>
      </span>}
      {mobile ? <BottomNav /> : <SidebarPro />} 
      {mobile && !previewImage &&
        <label onChange={handleFileChange} for="photo" class="weather-photo-upload">
          Upload Photo
          <input
            type="file"
            accept="image/*"
            id="photo" // Add an id to the file input
            name='photo'
            onChange={handleFileChange}
          />
        </label>
      }
      {previewImage && <PhotoPreview showLoader={showLoader} setPreviewImage={setPreviewImage} previewImage={previewImage} handleSubmit={handleSubmit} />}
      <LoadScript googleMapsApiKey={apiKey} libraries={libraries} mapIds={[mapId]}>
      <WeatherSettingsBtn setWeatherSettingsOpen={setWeatherSettingsOpen} />
      {showSettingsDropdown &&
        <WeatherMapUnits setShowSettingsDropdown={setShowSettingsDropdown} showSettingsDropdown={showSettingsDropdown} handleUnitChange={handleUnitChange} weatherUnits={weatherUnits} />
      }
      <WeatherLayerCheckBox weatherCheckboxStatus={weatherCheckboxStatus} handleSettingsCheckBoxChange={handleSettingsCheckBoxChange} />
      <GoogleMap
          ref={mapRef}
          mapContainerStyle={{ height: '100vh', width: '100vw' }}
          center={mapCenter} // Use user's location if available, otherwise use default center
          onLoad={onLoad}
        //   onClick={mapClickableForLocation && getLocationOnMap}
        //   onTouchEnd={mapClickableForLocation && getLocationOnMap}
          zoom={zoomLevel}
        //   onBoundsChanged={onBoundsChanged}
          options={
            {
            //   draggableCursor: mapClickableForLocation && 'crosshair',
              fullscreenControl: false,
              mapTypeControl: false, 
              mapId: mapId,
              streetViewControl: false,
              zoomControl: false
            }
            }
        >
        <div className='map-container'>
        {render && userLocation.lat !== 0 && userLocation.lng !== 0 && window.google && window.google.maps &&
          <Marker
            position={{ lat: userLocation.lat, lng: userLocation.lng }}
            icon={{
              url: require('../../images/userDot.png'),
              scaledSize: new window.google.maps.Size(15, 15)
              }}
            />          
          }
          {render && weatherCheckboxStatus.windSpeed === 'on' && window.google && window.google.maps &&
              Object.keys(weatherStation).map(stationKey => {

              let showBasedOnZoom = true

              if(zoomLevel < 6) {
                showBasedOnZoom = false
              } else if(weatherStation[stationKey].zoomLevel === 'off' && zoomLevel < 7) {
                showBasedOnZoom = false
              } else if(weatherStation[stationKey].zoomLevel === 'low' && zoomLevel < 8) {
                showBasedOnZoom = false
              } else if(weatherStation[stationKey].zoomLevel === 'medium' && zoomLevel < 10) {
                showBasedOnZoom = false
              } else if(weatherStation[stationKey].zoomLevel === 'high' && zoomLevel < 12) {
                showBasedOnZoom = false
              }

              if(showBasedOnZoom === false) {
                return null
              }


              const windSpeed = weatherStation[stationKey].windSpeed[0]
              const windDirection = weatherStation[stationKey].windDirection[0]
              const windDirectionAngle = getRotationAngle(windDirection);

              if(isNaN(windSpeed) && windSpeed !== 'calm') {
                return null;
              }

              return(
              
              <Marker
                  key={stationKey}
                  position={weatherStation[stationKey].coordinates}
                  icon={{
                    url: windSpeed > 0 ? windDirectionAngle : require('../../images/windArrows/windN.png'),
                    scaledSize: windSpeed !== 'calm' ? new window.google.maps.Size(22, 22) : new window.google.maps.Size(0, 0),
                    zIndex: 2, // Ensure icon appears above the label text
                  }}
                  label={{
                    text: convertUnitsUserSettings(windSpeed.toString(), weatherUnits.windSpeed),
                    color: 'black',
                    className: stationKey === hoveredMarker ? 'wind-speed-label wind-speed-label-hovered' : 'wind-speed-label',
                    }}
                  onClick={() => {
                    setSelectedMarker(weatherStation[stationKey]);
                  }}
                  onMouseOver={() => {
                    setHoveredMarker(stationKey);
                  }}
                  onMouseOut={() => {
                    setHoveredMarker(null);
                  }}
              >
              </Marker>
              )
            })
            }
            {render && weatherCheckboxStatus.waveHeight === 'on' && window.google && window.google.maps &&
              Object.keys(waveBuoy).map(buoyKey => {

                if (isNaN(waveBuoy[buoyKey].waveHeight[0]) && waveBuoy[buoyKey].waveHeight[0] !== 'calm') {
                  return null;
                }

                const waveHeight = waveBuoy[buoyKey].waveHeight[0]
                const waveHeight2 = waveBuoy[buoyKey].waveHeight[1]
                const waveHeightFixed = weatherUnits.waveHeight === 'feet' ? 
                parseFloat(waveHeight) < 10 ? 
                parseFloat(waveHeight).toFixed(1) : 
                parseFloat(waveHeight).toFixed(0) : 
                parseFloat(waveHeight).toFixed(1) ? 
                parseFloat(waveHeight).toFixed(2) :
                parseFloat(waveHeight).toFixed(1);

                const waveHeightDirection = waveHeight > waveHeight2 ? require('../../images/windArrows/waveUp.png') : require('../../images/windArrows/waveDown.png');

                return(

                <Marker
                  key={buoyKey}
                  position={waveBuoy[buoyKey].coordinates}
                  icon={{
                    url: waveHeightDirection,
                    scaledSize: new window.google.maps.Size(15, 15),
                  }}
                  label={{
                    text: convertUnitsUserSettings(waveHeightFixed.toString(), weatherUnits.waveHeight),
                    color: 'white',
                    className: 'wave-height-label',
                    className: buoyKey === hoveredMarker ? 'wave-height-label wave-height-label-hovered' : 'wave-height-label',
                  }}
                  onClick={() => {
                      setSelectedMarker(waveBuoy[buoyKey]);
                  }}
                  onMouseOver={() => {
                  setHoveredMarker(buoyKey);
                  }}
                  onMouseOut={() => {
                    setHoveredMarker(null);
                  }}
                >
                </Marker>
                )
            })
            }
            {/* {render &&
              Object.keys(waveBuoy).map((buoyKey, index) => {
                return (
                <Marker
                  key={index}
                  position={waveBuoy[buoyKey].coordinates}
                  icon={{
                    url: iconUrl,
                    anchor: new window.google.maps.Point(38, 28),  // Anchor point at the center of the icon
                    scaledSize: new window.google.maps.Size(65, 65)
                  }}
                />
              );
              }
            )} */}
            {render && zoomLevel > 6 && weatherCheckboxStatus.webCams === 'on' && window.google && window.google.maps &&
              newWebCamArray.map((webcam, index) => {

              let showBasedOnZoom = true
              
              if(zoomLevel < 9 && webcam.zoomLevel === 'low') {
                showBasedOnZoom = false
              }

              if(showBasedOnZoom === false) {
                return null
              }

              return (
                <Marker
                  key={index}
                  position={webcam.coordinates}
                  icon={{
                    url: require('../../images/webcamPurple.png'),
                    scaledSize: new window.google.maps.Size(15, 15),
                  }}
                  onClick={() => {
                    setSelectedWebcam(webcam);
                  }}
                />
              );
            })}
            {/* will not work if its a component that is imported in*/}
            {selectedWebcam && 
              <div className={selectedWebcam.className[0]}>
                <SimpleCloseBtn onClick={() => setSelectedWebcam(null)} />
                {selectedWebcam.img ? 
                <img src={selectedWebcam.url} alt="Webcam" className={selectedWebcam.className[3]} />
                :
                <iframe
                    title="Webcam Viewer"
                    className={selectedWebcam.className[1]}
                    src={selectedWebcam.url}
                    width="100%"
                    height="100%"
                    scrolling='no'
                />
                }
                <div className={selectedWebcam.className[2]}></div>
            </div>
            }       
            {render && weatherCheckboxStatus.photos === 'on' && window.google && window.google.maps &&    
              photoArray.map((photo, index) => {
                return (
                  <Marker
                    key={index}
                    position={{ lat: parseFloat(photo.metadata.customMetadata.latitude), lng: parseFloat(photo.metadata.customMetadata.longitude) }}
                    icon={{
                      url: photo.downloadURL,
                      scaledSize: new window.google.maps.Size(30, 30),
                    }}
                    onClick={() => {
                      console.log(photo)
                      setSelectedPhoto(photo);
                    }}
                  />
                );              
              })
            }
            {selectedPhoto && <InfoWindow
              position={{ lat: parseFloat(selectedPhoto.metadata.customMetadata.latitude), lng: parseFloat(selectedPhoto.metadata.customMetadata.longitude) }}
              onCloseClick={() => {
                setSelectedPhoto(null);
              }}
            >
              <div> 
                <img src={selectedPhoto.downloadURL} alt="Photo" style={{ maxWidth: '100%', maxHeight: '400px' }} />
                <h4 style={{textAlign: 'center', margin: 3, padding: 0}}>{transformTime(selectedPhoto.metadata.timeCreated)}</h4>
              </div>
            </InfoWindow>}            
            {renderInfoWindow()}
          </div>
        </GoogleMap>
      </LoadScript>
      <Footer />
    </div>
  );
}

export default WeatherMap;
